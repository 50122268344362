import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { gql } from 'apollo-angular';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiAutocomplete, OuiAutocompleteOption } from 'omnium-ui/form-field';
import { MenuNode } from 'omnium-ui/shared';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { FichierOperationInvestisseurService } from 'projects/box-backoffice/src/services/fichier-operation-investisseur.service';
import { CopyNonConformiteComponent } from 'projects/box-lib/src/lib/components/control-file-pdf-viewer/copy-non-conformite/copy-non-conformite.component';
import { UpdateConsultantDialogComponent } from 'projects/box-lib/src/lib/components/control-file-pdf-viewer/update-consultant-dialog/update-consultant-dialog.component';
import { CreateBoxDocTypeDialogComponent } from 'projects/box-lib/src/lib/components/create-box-doc-type-dialog/create-box-doc-type-dialog.component';
import { DEFAULT_BOX_DOCUMENT_TYPE } from 'projects/box-lib/src/lib/models/types';
import { AuthService } from 'projects/box-lib/src/lib/services/auth-service.service';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { firstValueFrom } from 'rxjs';
import { FichierOperationStatutEnum } from '../../models/FichierOperationStatutEnum';
import { LocalFileInfo } from '../../models/LocalFileInfo';
import {
  BoxDocumentType,
  CreateFichierOperationInput,
  CreateNonConformiteFichierInput,
  FichierOperation,
  FichierOperationHistoryRecord,
  FichierOperationHistoryRecordTypes,
  FileMetadata,
  FileMetadataUpdateInput,
  NonConformiteFichier,
  NonConformiteFichiersCollectionSegment,
  Operation,
  OperationStateTransitionTrigger,
  Statut,
} from '../../models/generated/graphql';
import {
  FICHIER_STATUT_COMPLIANT_ID,
  FICHIER_STATUT_CREATED_ID,
  FICHIER_STATUT_NONCOMPLIANT_ID,
  FICHIER_STATUT_UPDATEDBYCONSULTANT_ID,
} from '../../models/graphqlData';
import { fichierOperationControlFragment } from '../../models/graphqlFragments';
import { DocumentsService } from '../../services/documents.service';
import { OperationsService } from '../../services/operations.service';
import { deepCopy } from '../../utils/deepCopy';
import { fileLabel } from '../../utils/fichierOperationLabel';
import { openFilePicker } from '../../utils/filesUtils';
import { ConfirmationSimpleComponent } from '../confirmation-simple/confirmation-simple.component';
import { BoxDocumentViewerService } from '../document-viewer/document-viewer-panel/document-viewer-panel.service';
import {
  AddArianeDocumentViewerComponent,
  AddArianeResult,
} from '../pdf-viewer/add-ariane/add-ariane-document-viewer.component';
import {
  AddUploadDocumentViewerComponent,
  AddUploadResult,
} from '../pdf-viewer/add-upload/add-upload-document-viewer.component';
import { DeclareMissingFileComponent } from './declare-missing-file/declare-missing-file.component';
import { FileHistoriqueDialogComponent } from './file-historique-dialog/file-historique-dialog.component';
import {
  SendNonConfirmiteDialogResult,
  SendNonConformiteDialogComponent,
} from './send-non-conformite-dialog/send-non-conformite-dialog.component';
import { TransmissionPartenaireDialogComponent } from './transmission-partenaire-dialog/transmission-partenaire-dialog.component';
import { UpdateDocumentTypeComponent } from './update-denomination-document/update-doc-type.component';

const UPDATEFILESTATUS = gql`
  mutation updateFichierOperationStatut($fichierOperationId: ID!, $fichierOperationStatutId: ID!) {
    updateFichierOperationStatut(
      fichierOperationId: $fichierOperationId
      fichierOperationStatutId: $fichierOperationStatutId
    ) {
      ...fichierOperationControl
    }
  }
  ${fichierOperationControlFragment}
`;

const FETCH_SIMILAR_NC = gql`
  query allSimilarNC($fileIds: [String]) {
    allNonConformiteFichiersPaginated(
      skip: 0
      take: 100
      where: { fichierOperation: { fileId: { in: $fileIds } } }
      order: [{ fichierOperationId: DESC }]
    ) {
      items {
        id
        categorie
        motif
        commentaire
        isActive
        isInstancePartenaire
        isInternal
        fichierOperationId
        fichierOperation {
          fileId
        }
      }
    }
  }
`;

const CREATENONCONFORMITE = gql`
  mutation createNonConformiteFichier($input: CreateNonConformiteFichierInput!) {
    createNonConformiteFichier(input: $input) {
      id
      fichierOperation {
        ...fichierOperationControl
      }
    }
  }
  ${fichierOperationControlFragment}
`;

const CREATEINSTANCEPARTENAIRE = gql`
  mutation createInstancePartenaire($input: CreateInstancePartenaireInput!) {
    createInstancePartenaire(input: $input) {
      id
      fichierOperation {
        ...fichierOperationControl
      }
    }
  }
  ${fichierOperationControlFragment}
`;

const CLOSENC = gql`
  mutation closeNonConformiteFichier($nonConformiteId: ID!) {
    closeNonConformiteFichier(nonConformiteId: $nonConformiteId) {
      id
      fichierOperation {
        ...fichierOperationControl
      }
    }
  }
  ${fichierOperationControlFragment}
`;

const REOPENNC = gql`
  mutation reOpenNonConformiteFichier($nonConformiteId: ID!) {
    reOpenNonConformiteFichier(nonConformiteId: $nonConformiteId) {
      id
      fichierOperation {
        ...fichierOperationControl
      }
    }
  }
  ${fichierOperationControlFragment}
`;

const UPDATEFIELDS = gql`
  mutation updateNonConformiteFichierFields(
    $nonConformiteId: ID!
    $isInternal: Boolean
    $categorie: String
    $motif: String
    $commentaire: String
  ) {
    updateNonConformiteFichierFields(
      nonConformiteId: $nonConformiteId
      isInternal: $isInternal
      categorie: $categorie
      motif: $motif
      commentaire: $commentaire
    ) {
      id
      fichierOperation {
        ...fichierOperationControl
      }
    }
  }
  ${fichierOperationControlFragment}
`;

const UPDATEFILE = gql`
  mutation updateFichierOperationFile($fichierOperationId: ID!, $fileId: String!) {
    updateFichierOperationFile(fichierOperationId: $fichierOperationId, fileId: $fileId) {
      ...fichierOperationControl
    }
  }
  ${fichierOperationControlFragment}
`;

const CREATEFICHIEROPERATION = gql`
  mutation createFichierOperation($input: CreateFichierOperationInput!) {
    createFichierOperation(input: $input) {
      ...fichierOperationControl
    }
  }
  ${fichierOperationControlFragment}
`;

const REMOVEFICHIEROPERATION = gql`
  mutation removeFichierOperationFromOperation($fichierOperationId: ID!) {
    removeFichierOperationFromOperation(fichierOperationId: $fichierOperationId) {
      id
      statut {
        id
        consultantLibelle
        backOfficeLibelle
      }
      activeOperationStateTransitionTriggers
      commentaireGestionnaire
      fichierOperations {
        ...fichierOperationControl
      }
    }
  }
  ${fichierOperationControlFragment}
`;

const fireOperationStateTransitionAndComment = gql`
  mutation fireOperationStateTransitionAndComment(
    $operationId: Int!
    $trigger: OperationStateTransitionTrigger!
    $commentaire: String
  ) {
    fireOperationStateTransitionAndComment(operationId: $operationId, trigger: $trigger, commentaire: $commentaire) {
      id
      statut {
        id
        consultantLibelle
        backOfficeLibelle
      }
      activeOperationStateTransitionTriggers
      commentaireGestionnaire
    }
  }
`;

@Component({
  selector: 'app-control-file-document-viewer',
  templateUrl: './control-file-document-viewer.component.html',
  styleUrls: ['./control-file-document-viewer.component.scss'],
})
export class ControlFileDocumentViewerComponent {
  fileIdToSimilarNCDictionnary: { [key: string]: NonConformiteFichier[] } = {};
  @Input({ required: true })
  files: FichierOperation[];

  @Input({ required: true })
  selectedFile: FichierOperation;

  @Input({ required: true })
  operationStatut: Statut;

  @Input({ required: true })
  operationTriggers: OperationStateTransitionTrigger[];

  @Input({ required: true })
  isConsultantOutDated: boolean;

  @Input({ required: true })
  operation: Operation;

  @Input()
  currentOperationGestionnaireComment?: string;

  @Output()
  close = new EventEmitter();

  @ViewChild('typeDocumentControl') typeDocAutoComplete: OuiAutocomplete<any>;

  documentUrl: string;
  safeBlobUrl: SafeResourceUrl;
  trustedDocumentUrl: SafeResourceUrl;

  hideNonConformPanel = true;
  fichiersAControler: FichierOperation[] = [];
  fichiersNonConformes: FichierOperation[] = [];
  fichiersConformes: FichierOperation[] = [];

  showConformButton: boolean;
  showAddNonComplianceButton: boolean;
  isConformFile: boolean;
  isMissingFile: boolean;

  nonConformiteList: NonConformiteFichier[] | undefined = [];
  closedNCsList: NonConformiteFichier[] | undefined = [];
  nonConformiteToEdit?: NonConformiteFichier;

  isValidateDisabled: boolean;
  isTransferToPartenaireDisabled: boolean = true;

  fileMenu: MenuNode[] = [];

  fileSimilarNCs: NonConformiteFichier[] = [];
  get isInstanceMode(): boolean {
    return (
      this.operationStatut.id == 310 ||
      this.files.some(fo => fo.nonConformiteFichiers.some(ncf => ncf.isInstancePartenaire == true))
    );
  }

  isInstanceInternal: boolean = false;
  instancesList: NonConformiteFichier[] | undefined = [];
  closedIPsList: NonConformiteFichier[] | undefined = [];
  conformityEntries: FichierOperationHistoryRecord[];
  instanceEntries: FichierOperationHistoryRecord[];
  validateButtonLabel = 'Envoyer les non-conformités';

  showUpdateFileForm: boolean = false;
  localFilesInfo: LocalFileInfo[] = [];
  updatedFileId: string | undefined;

  isCancelConformityDeclarationPossible: boolean = false;
  isAddUploading: boolean = false;
  isDeleting: boolean = false;

  protected reopenHistory: boolean = false;

  boxDocumentTypeControl = new FormControl<number | undefined>(undefined, [Validators.required]);
  boxDocumentTypeListOptions: OuiAutocompleteOption[] = [];

  constructor(
    private documentService: DocumentsService,
    private queryManager: QueryManagerService,
    private dialogModal: MatDialog,
    private ouiDialog: OuiDialogService,
    private investisseurService: FichierOperationInvestisseurService,
    private dialogService: OuiDialogService,
    private snackbarService: OuiSnackbarService,
    private operationService: OperationsService,
    private documentViewerPanelService: BoxDocumentViewerService,
    protected authService: AuthService
  ) {
    this.documentService.getBoxDocumentTypeList().then(boxDocumentTypes => {
      this.boxDocumentTypeListOptions = boxDocumentTypes
        .map(boxDocumentType => ({
          label: boxDocumentType.key,
          value: boxDocumentType.id,
        }))
        .filter(boxDocumentType => boxDocumentType.value != DEFAULT_BOX_DOCUMENT_TYPE.id); // Autre is not allowed to be selected
    });
  }

  ngOnInit() {
    if (this.files.length > 0 && this.selectedFile) {
      //at init select first file
      this.selectFile(this.selectedFile);
      this.setFilesCategoryByStatus(this.files);
      if (!this.isInstanceMode) {
        this.initSimilarNCDictionnary();
      }
    }
    if (this.isInstanceMode) {
      this.validateButtonLabel = 'Envoyer les instances au consultant';
    }

    this.boxDocumentTypeControl.valueChanges.subscribe(async newTypeId => {
      if (!newTypeId) {
        return;
      }
      const newType = await this.documentService.getBoxDocumentTypeById(newTypeId);
      if (newType && this.selectedFile.metadata?.fileId) {
        this.selectedFile.metadata.typeDocumentCode = newType.gedParentTypeCode;
        this.selectedFile.metadata.denomination = newType.key;
        const metadataInput: FileMetadataUpdateInput = {
          denomination: newType.key,
          typeDocumentCode: newType.gedParentTypeCode,
        };
        this.documentService.updateGedFileMetadata(this.selectedFile.metadata?.fileId, metadataInput);
      }
    });
  }

  initSimilarNCDictionnary() {
    let fileIds = this.files.map(f => f.fileId).filter(f => f);

    this.queryManager
      .query<{ allNonConformiteFichiersPaginated: NonConformiteFichiersCollectionSegment }>({
        query: FETCH_SIMILAR_NC,
        variables: {
          fileIds,
        },
      })
      .subscribe(async result => {
        if (result.data?.allNonConformiteFichiersPaginated?.items?.length) {
          this.fileIdToSimilarNCDictionnary = {};

          result.data.allNonConformiteFichiersPaginated.items.forEach(nc => {
            let fileId = nc.fichierOperation?.fileId;
            if (fileId) {
              if (!this.fileIdToSimilarNCDictionnary[fileId]) {
                this.fileIdToSimilarNCDictionnary[fileId] = [];
              }
              this.fileIdToSimilarNCDictionnary[fileId].push(nc);
            }
          });
        }
      });

    this.setFileSimilarNCs();
  }

  onCreateNewBoxDocumentType() {
    let dialogRef = this.dialogService.openDialog(CreateBoxDocTypeDialogComponent, {}, 'auto', '400 px');
    dialogRef.afterClosed().subscribe((resultData: BoxDocumentType) => {
      if (resultData) {
        this.boxDocumentTypeListOptions.push({
          label: resultData.key,
          value: resultData.id,
        });
      }
    });
  }

  shouldDocBeTyped(fichierOperation: FichierOperation): boolean {
    return fichierOperation.metadata?.typeDocumentCode === DEFAULT_BOX_DOCUMENT_TYPE.gedParentTypeCode;
  }

  setFileSimilarNCs() {
    if (!this.selectedFile?.fileId || !this.fileIdToSimilarNCDictionnary) {
      this.fileSimilarNCs = [];
      return;
    }
    const allSimilarNCs = this.fileIdToSimilarNCDictionnary[this.selectedFile.fileId] ?? [];
    const uniqueKeys = new Set<string>();
    // filter unique NCs (same motif, same category, same commentaire)
    const uniqueSimilarNCs = allSimilarNCs.filter((nc: NonConformiteFichier) => {
      const key = nc.motif + nc.categorie + nc.commentaire;
      if (!uniqueKeys.has(key)) {
        uniqueKeys.add(key);
        return true;
      } else {
        return false;
      }
    });
    this.fileSimilarNCs = uniqueSimilarNCs;
  }
  onDuplicateNC() {
    if (!this.selectedFile.fileId) {
      return;
    }
    const modalRef = this.dialogService.openDialog(CopyNonConformiteComponent, {
      similarNC: this.fileSimilarNCs.sort((a, b) => {
        //sort on category, then on motif
        const categorieComparison = a.categorie.localeCompare(b.categorie);
        if (categorieComparison !== 0) {
          return categorieComparison;
        }
        return a.motif.localeCompare(b.motif);
      }),
    });

    modalRef.afterClosed().subscribe((NCToCopy: NonConformiteFichier[]) => {
      if (NCToCopy && NCToCopy.length) {
        // create NC for each selected element
        let nonConformitesInputs: CreateNonConformiteFichierInput[] = NCToCopy.map(nc => ({
          categorie: nc.categorie,
          motif: nc.motif,
          commentaire: nc.commentaire,
          fichierOperationId: nc.fichierOperationId,
        }));

        nonConformitesInputs.forEach(nc => {
          this.createNonConformiteFichier(nc);
        });
      }
    });
  }

  // transmettre au consultant
  validate(): void {
    // fire Operation state trasition and close dialog
    this.AddCommentBeforeSendingNC();
    // this.fireOperationStateTransitionTriggerAndClose(OperationStateTransitionTrigger.GestionnaireTransfersOperationWithNc);
  }

  // transmettre au partenaire
  transferToPartenaire(): void {
    const modalRef = this.dialogService.openDialog(TransmissionPartenaireDialogComponent, {
      operationId: this.selectedFile.operationId,
    });

    modalRef.afterClosed().subscribe((trigger?: OperationStateTransitionTrigger) => {
      if (trigger) {
        this.fireOperationStateTransitionTriggerAndClose(trigger, null);
      }
    });
  }

  checkConformityDeclaration(file: FichierOperation) {
    this.conformityEntries = file.historyEntry.filter(
      entry => entry.type == FichierOperationHistoryRecordTypes.FichierOperationSetToConforme
    );
    this.instanceEntries = file.historyEntry.filter(
      entry => entry.type == FichierOperationHistoryRecordTypes.FichierOperationRaiseInstance
    );
    // we can not cancel a conformity declaration if the instance is newer than the conformity declaration or it is instance mode and the file has no instance partenaire
    this.isCancelConformityDeclarationPossible =
      this.isDeclarationLatest() &&
      !(this.isInstanceMode && !file.nonConformiteFichiers.some(ncf => ncf.isInstancePartenaire == true));
  }

  isDeclarationLatest(): boolean {
    if (this.conformityEntries.length > 0) {
      const latestConformity = this.conformityEntries.reduce((a, b) => (a.timestamp > b.timestamp ? a : b));
      if (this.instanceEntries.length > 0) {
        const latestInstance = this.instanceEntries.reduce((a, b) => (a.timestamp > b.timestamp ? a : b));
        return latestConformity.timestamp > latestInstance.timestamp;
      }
      return true;
    }
    return true;
  }
  createFileMenu() {
    const menuItems = [
      {
        name: 'Mettre à jour depuis les documents Ariane',
        action: () => {
          this.onUpdateByGedFiles();
        },
      },
      {
        name: 'Mettre à jour depuis mon ordinateur',
        action: () => {
          this.onUpdateOnComputer();
        },
      },
      // FIXME: waiting for API document update and its implementation
      // {
      //   name: 'Modifier le type du document',
      //   action: () => {
      //     this.onUpdateFileType();
      //   },
      //   disabled: !!this.selectedFile.natureDocumentRequis?.natureDocument?.nom,
      // },
      {
        name: 'Historique',
        action: () => {
          this.openSelectedFileHistory();
        },
      },
    ];

    if (!this.selectedFile.fileId) {
      menuItems.push({
        name: 'Supprimer le document manquant',
        action: () => {
          this.onRemoveFichierFromOperation();
        },
      });
    }

    return menuItems;
  }

  openSelectedFileHistory() {
    if (this.selectedFile.operationId) {
      const operationId = this.selectedFile.operationId;
      const fichierOperationId = this.selectedFile.id;

      const dialogRef = this.ouiDialog.openDialog(
        FileHistoriqueDialogComponent,
        {
          operationId: operationId,
          fichierOperationId: fichierOperationId,
        },
        '720px',
        '700px'
      );

      const dialogSub = dialogRef.componentInstance.documentOpen.subscribe((file: FileMetadata) => {
        this.reopenHistory = true;
        this.openDocumentViewer(file);

        dialogRef.close();
      });

      dialogRef.afterClosed().subscribe(() => {
        dialogSub.unsubscribe();
      });
    }
  }

  openDocumentViewer(fileMetadata?: FileMetadata) {
    this.documentViewerPanelService.openSimpleDocumentViewer({ fileMetadata: fileMetadata });

    if (this.reopenHistory) {
      firstValueFrom(this.documentViewerPanelService.viewerClosed$).then(() => {
        this.openSelectedFileHistory();
        this.reopenHistory = false;
      });
    }
  }

  toogleUpdateFileForm() {
    this.showUpdateFileForm = !this.showUpdateFileForm;
    if (this.showUpdateFileForm) {
      const modalRef = this.ouiDialog.openDialog(ConfirmationSimpleComponent, {
        title: 'Confirmer la mise à jour',
        message: 'Confirmez-vous la mise à jour du document ?',
        inputValidateButtonLabel: 'Mettre à jour',
        cancelLabel: 'Annuler',
      });

      modalRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.onFileUpdate();
        } else {
          this.onCancelFileUpdate();
        }
      });
    }
  }

  onUpdateOnComputer() {
    openFilePicker(
      'target_div',
      async (files: File[]) => {
        if (!files.every(file => this.documentService.isAuthorizedConsultantFile(file))) {
          this.snackbarService.open('Format ou poids de fichier non autorisé.', 'error', 5000, {
            horizontal: 'left',
            vertical: 'bottom',
          });
          return;
        }
        this.localFilesInfo = files.map(file => ({
          url: this.documentService.cacheLocalFile(file),
          file,
          investisseurId: this.selectedFile.estCoInvestisseur
            ? this.investisseurService.getCoInvestisseur()?.id
            : this.investisseurService.getInvestisseur()?.id,
        }));
        this.documentUrl = this.localFilesInfo[0].url;
        this.toogleUpdateFileForm();
      },
      false
    );
  }

  onUpdateByGedFiles() {
    const investisseurs = this.investisseurService.getAllInvestisseurs();
    const dialogRef = this.dialogModal.open(AddArianeDocumentViewerComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      height: '90%',
      width: '90%',
      panelClass: 'full-screen-modal',
      data: {
        investisseurs: investisseurs,
        natureDocumentRequis: this.selectedFile.natureDocumentRequis,
        hideComment: true,
      },
    });
    dialogRef.afterClosed().subscribe(async (resultData: AddArianeResult) => {
      const fileInfoList = resultData?.filesInfo;
      //if a file has been selected, add create a fichier Operation
      if (fileInfoList && Array.isArray(fileInfoList) && fileInfoList.length > 0) {
        this.updatedFileId = fileInfoList[0].metadata.fileId ?? undefined;
        this.toogleUpdateFileForm();
        let fileCacheUrl = await this.documentService.fetchAndCacheGedDocument(fileInfoList[0].metadata);
        if (fileCacheUrl) {
          this.documentUrl = fileCacheUrl;
          this.isMissingFile = false;
        } else {
          this.documentUrl = '';
          this.isMissingFile = true;
        }
      }
    });
  }

  async uploadUpdateFile() {
    if (this.localFilesInfo[0]) {
      const investisseurId =
        this.selectedFile.metadata?.investisseurId ??
        this.operation.investisseur?.id ??
        this.localFilesInfo[0].investisseurId;

      const type = this.selectedFile.natureDocumentRequis?.natureDocument?.boxDocumentType ?? DEFAULT_BOX_DOCUMENT_TYPE;
      this.updatedFileId = await this.documentService.uploadFile(
        this.localFilesInfo[0].file,
        investisseurId,
        type,
        type.key
      );
      this.investisseurService.updateInvestisseurFileData(investisseurId);
    }
  }

  onUpdateFileType() {
    const modalRef = this.dialogService.openDialog(
      UpdateDocumentTypeComponent,
      {
        file: this.selectedFile.metadata,
      },
      'auto',
      '411px'
    );
    modalRef.afterClosed().subscribe(updatedFile => {
      if (updatedFile) {
        this.selectedFile.metadata = deepCopy(updatedFile); // update updatedFile;
      }
    });
  }

  async onCancelFileUpdate() {
    this.updatedFileId = undefined; // reset the updatedFileId
    this.localFilesInfo = []; // reset localFile
    this.toogleUpdateFileForm(); // reset display
    await this.selectFile(this.selectedFile);
  }
  async onFileUpdate() {
    // case when we have to upload file before sending the response
    if (this.localFilesInfo[0]) {
      await this.uploadUpdateFile();
      if (this.updatedFileId) {
        this.updatFileForFichierOperation();
      }
      return;
    }
    this.updatFileForFichierOperation();
  }

  updatFileForFichierOperation() {
    this.queryManager
      .mutate<{ updateFichierOperationFile: FichierOperation }>({
        mutation: UPDATEFILE,
        variables: {
          fichierOperationId: this.selectedFile.id,
          fileId: this.updatedFileId,
        },
      })
      .subscribe(async result => {
        if (result.data?.updateFichierOperationFile) {
          const updatedFile = result.data?.updateFichierOperationFile;
          this.afterFileUpdate(updatedFile);
          await this.selectFile(updatedFile);
          this.updatedFileId = undefined;
          this.toogleUpdateFileForm();
        }
      });
  }
  async selectFile(file: FichierOperation) {
    if (file.metadata) {
      this.isMissingFile = false;
      let fileCacheUrl = await this.documentService.fetchAndCacheGedDocument(file.metadata);
      if (fileCacheUrl) {
        this.documentUrl = fileCacheUrl;
      } else {
        this.documentUrl = '';
        this.isMissingFile = true;
      }
    } else {
      this.documentUrl = '';
      this.isMissingFile = true;
    }
    this.selectedFile = file;

    //clear autocompletes for type document // FIXME  : only use of this.boxDocumentTypeControl.setValue is not possible because autocomplete keep a memory
    // of the last selected value in visible option list
    this.boxDocumentTypeControl.setValue(undefined);
    this.boxDocumentTypeControl.reset();
    this.typeDocAutoComplete?.empty();

    this.setNCsAndInstancesList(file.nonConformiteFichiers);
    this.checkConformityDeclaration(file);
    this.checkButtonToDisplay();
    this.fileMenu = this.createFileMenu();
    this.setFileSimilarNCs();
  }

  onMissingFile() {
    const modalRef = this.dialogService.openDialog(
      DeclareMissingFileComponent,
      {
        operationId: this.selectedFile.operationId,
        isInstanceMode: this.isInstanceMode,
      },
      'auto',
      '540px'
    );

    modalRef.afterClosed().subscribe((missingFile: FichierOperation) => {
      if (missingFile && missingFile.id) {
        this.files.push(missingFile);
        this.afterFileUpdate(missingFile);
        this.selectFile(missingFile);
      }
    });
  }

  AddCommentBeforeSendingNC() {
    const modalRef = this.dialogService.openDialog(
      SendNonConformiteDialogComponent,
      { isInstanceMode: this.isInstanceMode, currentComment: this.currentOperationGestionnaireComment },
      'auto',
      '560px'
    );

    modalRef.afterClosed().subscribe(async (result: SendNonConfirmiteDialogResult | undefined) => {
      if (!result) {
        return;
      }

      let trigger = OperationStateTransitionTrigger.GestionnaireTransfersOperationWithNc;
      let snackMessage = 'Les non-conformités ont été envoyées';

      if (this.isInstanceMode) {
        trigger = OperationStateTransitionTrigger.GestionnaireTransfersOperationWithInstance;
        snackMessage = 'Les instances partenaire ont été envoyées';
      }

      const commentaire = result.commentaire ? result.commentaire : undefined;
      const isNCsent = await this.fireOperationStateTransitionTriggerAndClose(trigger, commentaire);

      if (isNCsent) {
        this.snackbarService.open(snackMessage, 'success', 5000, {
          horizontal: 'left',
          vertical: 'bottom',
        });
      }
    });
  }

  onCompliant() {
    this.checkDocTypeOnCompliance();
    this.DeclareFileStatus(FichierOperationStatutEnum.compliant);
  }

  async checkDocTypeOnCompliance() {
    // if a doc is defined conform with valid box doc type then we add a Tag to operation
    if (!this.selectedFile.natureDocumentRequisId) {
      const selectedFileBoxType = await this.documentService.getBoxDocumentTypeByDenomination(
        this.selectedFile.metadata?.denomination ?? undefined
      );

      if (!selectedFileBoxType || selectedFileBoxType?.id === DEFAULT_BOX_DOCUMENT_TYPE.id) {
        //no valid box doc type ==> create tag
        const currentTags = this.operation.tags;
        if (!currentTags.find(tag => tag.id === 12)) {
          try {
            const newTags = currentTags.map(tag => tag.id);
            newTags.push(12); // tag 12 == CONTIENT_FICHIER_NON_TYPE
            var result = await this.operationService.updateOperationTag(this.operation.id, newTags);
            if (result) {
              this.operation.tags = result.tags;
            }
          } catch (error) {
            this.snackbarService.open('Une erreur est survenue durant la mise à jour du tag', 'error', 5000);
          }
        }
      }
    }
  }

  onCancelCompliance() {
    this.DeclareFileStatus(FichierOperationStatutEnum.created);
  }
  onAddNonCompliance() {
    this.hideNonConformPanel = false;
  }

  checkButtonToDisplay() {
    this.checkFooterButtons();
    this.showConformButton = this.isShowConformButton();
    this.showAddNonComplianceButton =
      this.selectedFile.fichierOperationStatut?.statut !== FichierOperationStatutEnum.compliant && !this.isInstanceMode;
    this.isConformFile = this.selectedFile.fichierOperationStatut?.statut === FichierOperationStatutEnum.compliant;
  }

  isShowConformButton(): boolean {
    return (
      this.selectedFile.fichierOperationStatut?.statut === FichierOperationStatutEnum.created ||
      this.selectedFile.fichierOperationStatut?.statut === FichierOperationStatutEnum.updatedByConsulant
    );
  }
  checkFooterButtons() {
    const transferConusultantTriggers = [
      OperationStateTransitionTrigger.GestionnaireTransfersOperationWithNc,
      OperationStateTransitionTrigger.GestionnaireTransfersOperationWithInstance,
    ];
    this.isValidateDisabled = !this.operationTriggers.some(trigger => transferConusultantTriggers.includes(trigger));

    this.isTransferToPartenaireDisabled = !this.operationService.canSendToPartenaire(this.operationTriggers);
  }

  openNonConformiteFichierForm(nc: NonConformiteFichier) {
    this.nonConformiteToEdit = deepCopy(nc); //deep cloning
    this.hideNonConformPanel = false;
  }

  getStatusIdFromEnum(status: string): number {
    switch (status) {
      case FichierOperationStatutEnum.compliant:
        return FICHIER_STATUT_COMPLIANT_ID;
      case FichierOperationStatutEnum.created:
        return FICHIER_STATUT_CREATED_ID;
      case FichierOperationStatutEnum.updatedByConsulant:
        return FICHIER_STATUT_UPDATEDBYCONSULTANT_ID;
      case FichierOperationStatutEnum.nonCompliant:
        return FICHIER_STATUT_NONCOMPLIANT_ID;
      default:
        return -1;
    }
  }

  DeclareFileStatus(status: string) {
    // check if the string status is a member of the statusEnum
    if (Object.values(FichierOperationStatutEnum).includes(status as unknown as FichierOperationStatutEnum)) {
      const statusId = this.getStatusIdFromEnum(status);
      this.queryManager
        .mutate<{ updateFichierOperationStatut: FichierOperation }>({
          mutation: UPDATEFILESTATUS,
          variables: {
            fichierOperationId: this.selectedFile.id,
            fichierOperationStatutId: statusId,
          },
        })
        .subscribe(result => {
          const updatedFile = result.data?.updateFichierOperationStatut;
          this.afterFileUpdate(updatedFile);
        });
    }
  }

  createNonCompliance(nonConformite: CreateNonConformiteFichierInput | null) {
    if (!nonConformite) {
      this.hideNonConformPanel = true;
      this.nonConformiteToEdit = undefined;
      this.isInstanceInternal = false;
      return;
    }
    if (this.isInstanceMode) {
      this.createInstancePartenaire(nonConformite);
      return;
    }
    this.createNonConformiteFichier(nonConformite);
  }

  createNonConformiteFichier(nonConformite: CreateNonConformiteFichierInput) {
    this.queryManager
      .mutate<{ createNonConformiteFichier: NonConformiteFichier }>({
        mutation: CREATENONCONFORMITE,
        variables: {
          input: {
            categorie: nonConformite.categorie,
            motif: nonConformite.motif,
            commentaire: nonConformite.commentaire,
            fichierOperationId: this.selectedFile.id,
          },
        },
      })
      .subscribe(result => {
        const updatedFile = result.data?.createNonConformiteFichier.fichierOperation;
        this.afterFileUpdate(updatedFile);
        this.hideNonConformPanel = true;
      });
  }

  createInstancePartenaire(nonConformite: CreateNonConformiteFichierInput) {
    this.queryManager
      .mutate<{ createInstancePartenaire: NonConformiteFichier }>({
        mutation: CREATEINSTANCEPARTENAIRE,
        variables: {
          input: {
            categorie: nonConformite.categorie,
            motif: nonConformite.motif,
            commentaire: nonConformite.commentaire,
            fichierOperationId: this.selectedFile.id,
            isInternal: this.isInstanceInternal,
          },
        },
      })
      .subscribe(result => {
        const updatedFile = result.data?.createInstancePartenaire.fichierOperation;
        this.afterFileUpdate(updatedFile);
        this.hideNonConformPanel = true;
        this.isInstanceInternal = false;
      });
  }
  closeNonConformite(nonConformite: NonConformiteFichier) {
    this.queryManager
      .mutate<{ closeNonConformiteFichier: NonConformiteFichier }>({
        mutation: CLOSENC,
        variables: {
          nonConformiteId: nonConformite.id,
        },
      })
      .subscribe(result => {
        const updatedFile = result.data?.closeNonConformiteFichier?.fichierOperation;
        this.afterFileUpdate(updatedFile);
        this.hideNonConformPanel = true;
      });
  }

  reOpenNonConformite(nonConformite: NonConformiteFichier) {
    this.queryManager
      .mutate<{ reOpenNonConformiteFichier: NonConformiteFichier }>({
        mutation: REOPENNC,
        variables: {
          nonConformiteId: nonConformite.id,
        },
      })
      .subscribe(result => {
        const updatedFile = result.data?.reOpenNonConformiteFichier?.fichierOperation;
        this.afterFileUpdate(updatedFile);
        this.hideNonConformPanel = true;
      });
  }

  updateNonConformiteFields(nonConformite: NonConformiteFichier) {
    this.queryManager
      .mutate<{ updateNonConformiteFichierFields: NonConformiteFichier }>({
        mutation: UPDATEFIELDS,
        variables: {
          nonConformiteId: nonConformite.id,
          categorie: nonConformite.categorie,
          motif: nonConformite.motif,
          commentaire: nonConformite.commentaire,
          isInternal: nonConformite.isInstancePartenaire ? nonConformite.isInternal : false,
        },
      })
      .subscribe(result => {
        const updatedFile = result.data?.updateNonConformiteFichierFields?.fichierOperation;
        this.afterFileUpdate(updatedFile);
        this.hideNonConformPanel = true;
      });
  }

  afterFileUpdate(updatedFile: FichierOperation | undefined) {
    // update the list files
    if (updatedFile && updatedFile.operation) {
      let replaceIndex = this.files.findIndex(f => f.id === updatedFile.id);
      if (replaceIndex > -1) {
        this.files[replaceIndex] = updatedFile;
        // update files categories
        this.setFilesCategoryByStatus(this.files);
        this.selectedFile.fichierOperationStatut = updatedFile.fichierOperationStatut;
        this.selectedFile.historyEntry = updatedFile.historyEntry;
        // update nonConformiteList
        this.setNCsAndInstancesList(updatedFile.nonConformiteFichiers);
        this.checkConformityDeclaration(updatedFile);

        this.nonConformiteToEdit = undefined;
        // update operation statut and triggers
        this.operationStatut = updatedFile.operation.statut;
        this.operationTriggers = updatedFile.operation.activeOperationStateTransitionTriggers;
        // check button
        this.checkButtonToDisplay();
      }
    }
  }

  onAddInstanceMode() {
    if (!this.isConsultantOutDated) {
      this.hideNonConformPanel = false;
      return;
    }
    const modalRef = this.dialogService.openDialog(
      UpdateConsultantDialogComponent,
      { operationId: this.selectedFile.operationId, investisseurId: this.investisseurService.getInvestisseur()?.id },
      'auto',
      '413px'
    );
    modalRef.afterClosed().subscribe(result => {
      if (result) {
        this.hideNonConformPanel = false;
      }
    });
  }

  setIsInternalInstance(isInternal: boolean) {
    this.isInstanceInternal = isInternal;
  }
  async fireOperationStateTransitionTriggerAndClose(
    trigger: OperationStateTransitionTrigger,
    commentaire?: string | null
  ): Promise<boolean> {
    if (this.operationTriggers.includes(trigger)) {
      const result = await firstValueFrom(
        this.queryManager.mutate<{ fireOperationStateTransitionAndComment: Operation }>({
          mutation: fireOperationStateTransitionAndComment,
          variables: {
            operationId: this.selectedFile.operationId,
            trigger: trigger,
            commentaire: commentaire,
          },
        })
      );
      if (result.data?.fireOperationStateTransitionAndComment?.statut) {
        this.operationStatut = result.data?.fireOperationStateTransitionAndComment.statut;
        this.operationTriggers =
          result?.data?.fireOperationStateTransitionAndComment.activeOperationStateTransitionTriggers;
        this.close.emit();
        return true;
      }
    }
    return false;
  }

  setFilesCategoryByStatus(files: FichierOperation[]) {
    this.fichiersAControler = files.filter(
      f =>
        f.fichierOperationStatut?.statut == FichierOperationStatutEnum.created ||
        f.fichierOperationStatut?.statut == FichierOperationStatutEnum.updatedByConsulant
    );
    this.fichiersNonConformes = files.filter(
      f => f.fichierOperationStatut?.statut == FichierOperationStatutEnum.nonCompliant
    );
    this.fichiersConformes = files.filter(
      f => f.fichierOperationStatut?.statut == FichierOperationStatutEnum.compliant
    );
  }

  setNCsAndInstancesList(NCIs: NonConformiteFichier[] | null) {
    this.nonConformiteList = NCIs?.filter((nc: any) => nc.isActive && nc.isInstancePartenaire != true);
    this.instancesList = NCIs?.filter((nc: any) => nc.isInstancePartenaire == true && nc.isActive);
    this.closedIPsList = NCIs?.filter((nc: any) => nc.isInstancePartenaire == true && !nc.isActive);
    this.closedNCsList = NCIs?.filter((nc: any) => !nc.isActive && nc.isInstancePartenaire != true);
  }

  onAddFichierOperation() {
    openFilePicker(
      'target_div',
      (files: File[]) => {
        if (!files.every(file => this.documentService.isAuthorizedConsultantFile(file))) {
          this.snackbarService.open('Format ou poids de fichier non autorisé.', 'error', 5000, {
            horizontal: 'left',
            vertical: 'bottom',
          });
          return;
        }
        const dialogRef = this.dialogModal.open(AddUploadDocumentViewerComponent, {
          maxWidth: '90vw',
          maxHeight: '90vh',
          height: '90%',
          width: '90%',
          panelClass: 'full-screen-modal',
          autoFocus: false,
          data: {
            files,
            typeSelectable: true,
            typeLabel: null,
            investisseurs: this.investisseurService.getAllInvestisseurs(),
          },
        });
        dialogRef.afterClosed().subscribe(async (result: AddUploadResult) => {
          if (Array.isArray(result?.filesInfo) && result?.filesInfo?.length > 0) {
            this.isAddUploading = true;

            // we have some file to upload to server in a first step and then we return fileId to create fichier Operation.
            const localFileInfos: LocalFileInfo[] = result.filesInfo;
            const lfi = localFileInfos[0];
            const investisseurId = localFileInfos[0].investisseurId;
            if (!investisseurId) return;
            var fileId = await this.documentService.uploadFile(
              lfi.file,
              investisseurId,
              lfi.boxDocumentType,
              lfi.denomination
            );

            if (fileId && this.selectedFile.operationId) {
              this.investisseurService.updateInvestisseurFileData(investisseurId);
              await this.createFichierOperation(
                fileId,
                this.selectedFile.operationId,
                !!investisseurId && investisseurId === this.investisseurService.getCoInvestisseur()?.id,
                lfi.comment
              );
            }
          }
        });
      },
      false
    );
  }

  async createFichierOperation(fileId: string, operationId: number, forCoInvestisseur: boolean, commentaire?: string) {
    const input: CreateFichierOperationInput = {
      fileId: fileId,
      operationId: operationId,
      commentaire: commentaire,
      estHorsConfig: true,
      estCoInvestisseur: forCoInvestisseur,
    };

    const result = (
      await firstValueFrom(
        this.queryManager.mutate<{ createFichierOperation: FichierOperation }>({
          mutation: CREATEFICHIEROPERATION,
          variables: {
            input,
          },
        })
      )
    ).data?.createFichierOperation;
    if (result) {
      this.files = [...this.files, result];
      this.fichiersAControler = [...this.fichiersAControler, result];
      await this.selectFile(result);
      this.afterFileUpdate(result);
    }
    this.isAddUploading = false;
  }
  async removeFichierFromOperation() {
    this.isDeleting = true;
    const fromOperation = (
      await firstValueFrom(
        this.queryManager.mutate<{ removeFichierOperationFromOperation: Operation }>({
          mutation: REMOVEFICHIEROPERATION,
          variables: {
            fichierOperationId: this.selectedFile.id,
          },
        })
      )
    ).data?.removeFichierOperationFromOperation;
    if (fromOperation) {
      this.files = fromOperation.fichierOperations;
      this.setFilesCategoryByStatus(this.files);
      // update operation statut and triggers
      this.operationStatut = fromOperation.statut;
      this.operationTriggers = fromOperation.activeOperationStateTransitionTriggers;
      this.setNCsAndInstancesList([]);
      this.checkConformityDeclaration(this.selectedFile);
      await this.selectFile(this.files[0]);
      this.isDeleting = false;
    }
  }

  onRemoveFichierFromOperation() {
    if (this.files.length > 1) {
      const modalRef = this.ouiDialog.openDialog(
        ConfirmationSimpleComponent,
        {
          title: 'Supprimer le document de l’opération ?',
          message: 'Êtes-vous certain de vouloir supprimer définitivement ce document ?',
          validateButtonLabel: 'Oui, supprimer le document',
          cancelButtonLabel: 'Non',
        },
        'auto',
        '520px'
      );
      modalRef.afterClosed().subscribe((isValidated: boolean) => {
        if (isValidated) {
          this.removeFichierFromOperation();
        }
      });
    }
  }

  getFileLabel(file: FichierOperation): string {
    return fileLabel(file);
  }

  formatTitleWithCount(title: string, count: number, accord: boolean = true) {
    if (accord && count > 1) {
      title += 's';
    }
    return `${title} (${count})`;
  }
}
