<div class="flex-vertical gap-32">
  <oui-button-toggle [options]="investisseurTypeOptions" [control]="investisseurTypeTogglecontrol"></oui-button-toggle>

  <app-search-investisseur-autocomplete
    [isRequired]="true"
    [label]="searchInvestisseurLabel"
    [initInvestisseur]="draftOperationsService.investisseur"
    (onInvestisseurPersonneSelected)="onInvestisseurSelected($event)"
    [isInvestisseurSet]="draftOperationsService.getMaxOperationsStateId() >= 5"
    [isContactStatutExcluded]="false"
    [typePersonnes]="[draftOperationsService.investisseurType]"></app-search-investisseur-autocomplete>
  <ng-container *ngIf="investisseurTypeTogglecontrol.value == 1">
    <app-search-investisseur-autocomplete
      [isRequired]="true"
      *ngIf="draftOperationsService.withCoInvestisseur"
      [initInvestisseur]="draftOperationsService.coInvestisseur"
      [label]="'Co-investisseur'"
      (onInvestisseurPersonneSelected)="onCoInvestisseurSelected($event)"
      [isInvestisseurSet]="draftOperationsService.getMaxOperationsStateId() >= 5"
      [typePersonnes]="[typeInv.PersonnePhysique]"></app-search-investisseur-autocomplete>
    <div class="flex-vertical gap-16">
      <oui-slide-toggle
        label="Co-investisseur"
        (checkedChange)="onCoInvestisseurToggled()"
        [checked]="!!draftOperationsService.coInvestisseur"
        *ngIf="!(draftOperationsService.getMaxOperationsStateId() >= 5)">
      </oui-slide-toggle>
      <oui-banner
        type="simple"
        title="Les contreparties pour les dossiers de SCPI en démembrement ne sont pas considérées comme des co-investisseurs, il faut faire une déclaration indépendante."
        [action]="bannerAction"></oui-banner>
    </div>
  </ng-container>
  <ng-container *ngIf="investisseurTypeTogglecontrol.value == 2">
    <div class="flex-vertical gap-8">
      <div class="body-1">La personne morale n’est pas dans liste ?</div>
      <oui-button class="demandeButton" type="outlined" (click)="onDemandeCreationClick()"
        >Faire une demande de création</oui-button
      >
    </div>
  </ng-container>
</div>
