<app-root-document-viewer
  [documentUrl]="documentUrl"
  [isMissingFile]="isMissingFile"
  [validateButtonLabel]="validateButtonLabel"
  [isValidateDisabled]="isValidateDisabled"
  cancelButtonLabel="Enregistrer et quitter"
  optionnalButton1Label="Transmettre au partenaire"
  [isOptionnalButton1Disabled]="isTransferToPartenaireDisabled"
  optionnalButtonColor="valid"
  (onValidate)="validate()"
  (onCancel)="close.emit()"
  (onOptionnalButton1Click)="transferToPartenaire()">
  <div class="flex-vertical" left-panel>
    <div id="target_div" class="display-none"></div>
    <div class="flex-vertical padding-24 gap-16">
      <h5>Contrôle des documents</h5>
      <div class="flex-vertical gap-16 flex-start">
        <oui-button type="text" iconPrefix="unknown_document" (click)="onMissingFile()">
          Déclarer un document manquant
        </oui-button>
        <oui-button
          type="text"
          *ngIf="!isAddUploading"
          iconPrefix="add_circle_outline"
          (click)="onAddFichierOperation()"
          >Ajouter un document</oui-button
        >
        <div class="flex-horizontal align-self-center" *ngIf="isAddUploading">
          <oui-loading size="small"></oui-loading>
        </div>
      </div>
    </div>
    <div>
      <oui-accordion
        [expanded]="fichiersAControler.length > 0"
        [disabled]="fichiersAControler.length < 1"
        *ngIf="fichiersAControler">
        <oui-accordion-header class="padding-vertical-8 padding-lateral-24 background-background">
          <oui-accordion-title class="color-alert subtitle-1">{{
            formatTitleWithCount('A contrôler', fichiersAControler.length, false)
          }}</oui-accordion-title>
        </oui-accordion-header>
        <div class="flex-vertical">
          <app-file-item
            *ngFor="let file of fichiersAControler"
            [fileName]="file.metadata?.fileName ?? undefined"
            [label]="getFileLabel(file)"
            [selected]="selectedFile.id === file.id"
            [status]="file.fileId ? 'a_controler' : 'manquant'"
            (click)="selectFile(file)"></app-file-item>
        </div>
      </oui-accordion>
      <oui-accordion
        [expanded]="fichiersNonConformes.length > 0"
        [disabled]="fichiersNonConformes.length < 1"
        *ngIf="fichiersNonConformes">
        <oui-accordion-header class="padding-vertical-8 padding-lateral-24 background-background">
          <oui-accordion-title class="color-error subtitle-1">{{
            formatTitleWithCount('Non conforme', fichiersNonConformes.length)
          }}</oui-accordion-title>
        </oui-accordion-header>
        <div class="flex-vertical">
          <app-file-item
            *ngFor="let file of fichiersNonConformes"
            [fileName]="file.metadata?.fileName"
            [label]="getFileLabel(file)"
            [selected]="selectedFile.id === file.id"
            [status]="file.fileId ? 'a_corriger' : 'manquant'"
            (click)="selectFile(file)"></app-file-item>
        </div>
      </oui-accordion>
      <oui-accordion
        [expanded]="fichiersConformes.length > 0"
        [disabled]="fichiersConformes.length < 1"
        *ngIf="fichiersConformes">
        <oui-accordion-header class="padding-vertical-8 padding-lateral-24 background-background">
          <oui-accordion-title class="color-valid subtitle-1">{{
            formatTitleWithCount('Conforme', fichiersConformes.length)
          }}</oui-accordion-title>
        </oui-accordion-header>
        <div class="flex-vertical">
          <app-file-item
            *ngFor="let file of fichiersConformes"
            [fileName]="file.metadata?.fileName ?? undefined"
            [label]="getFileLabel(file)"
            [selected]="selectedFile.id === file.id"
            status="conforme"
            (click)="selectFile(file)"></app-file-item>
        </div>
      </oui-accordion>
    </div>
  </div>

  <ng-container right-panel *ngIf="hideNonConformPanel; else nonConformiteForm">
    <lib-document-viewer-header
      [documentName]="getFileLabel(selectedFile)"
      [fileName]="selectedFile.metadata?.fileName ?? undefined"
      [menu]="!showUpdateFileForm ? fileMenu : undefined"
      [dateAjout]="selectedFile.metadata?.dateDeCreation"
      [showCloseButton]="false"></lib-document-viewer-header>

    <div *ngIf="!showUpdateFileForm && selectedFile.commentaire" class="flex-vertical">
      <div class="body-2 color-on-surface-medium">Commentaire</div>
      <div class="body-2 color-on-surface-emphasis" [innerText]="selectedFile.commentaire"></div>
    </div>
    <ng-container *ngIf="!showUpdateFileForm; else updateFileForm">
      <div class="padding-16 flex-vertical gap-16 orangeFrame" *ngIf="selectedFile.reponseConsultant && !isConformFile">
        <div class="subtitle-2 color-alert">Réponse du consultant:</div>
        <div class="body-2" [innerText]="selectedFile.reponseConsultant"></div>
      </div>
      <div class="flex-vertical gap-24" *ngIf="isConformFile">
        <app-file-conform-status-stamp [historyEntries]="selectedFile.historyEntry"></app-file-conform-status-stamp>
        <div class="flex-horizontal justify-center" *ngIf="isCancelConformityDeclarationPossible">
          <oui-button type="text" iconPrefix="undo" (click)="onCancelCompliance()">Annuler la déclaration</oui-button>
        </div>
      </div>
      <div class="flex-vertical gap-16" *ngIf="selectedFile.fileId || isDeleting">
        <ng-container *ngIf="!isDeleting; else deleteLoading">
          <oui-button
            type="contained"
            color="valid"
            iconPrefix="check"
            (click)="onCompliant()"
            *ngIf="showConformButton"
            >Déclarer conforme</oui-button
          >
          <oui-button
            type="contained"
            color="error"
            iconPrefix="add"
            (click)="onAddNonCompliance()"
            *ngIf="showAddNonComplianceButton"
            >Ajouter une non-conformité</oui-button
          >
          <ng-container *ngIf="isInstanceMode">
            <oui-button
              type="contained"
              iconPrefix="add"
              color="error"
              (click)="onAddInstanceMode()"
              *ngIf="isInstanceMode"
              >Ajouter une instance partenaire</oui-button
            >
          </ng-container>
          <ng-container *ngIf="!isInstanceMode && showAddNonComplianceButton">
            <oui-button
              type="outlined"
              iconPrefix="content_copy"
              color="error"
              (click)="onDuplicateNC()"
              [disabled]="!(selectedFile.fileId && fileSimilarNCs && fileSimilarNCs.length > 0)"
              >Dupliquer une non-conformité</oui-button
            >
          </ng-container>
          <!-- FIXME waiting for API document update and its implementation -->
          <!-- <div *ngIf="shouldDocBeTyped(selectedFile)" class="width-100pc margin-bottom-16 top-margin-16">
            Veuillez sélectionner un type pour ce document:
            <oui-autocomplete
              #typeDocumentControl
              label="Type du document"
              [control]="boxDocumentTypeControl"
              [options]="boxDocumentTypeListOptions"
              class="width-100pc top-margin-8">
            </oui-autocomplete>
            <oui-button
              type="outlined"
              *ngIf="authService.isAdminRole()"
              (click)="onCreateNewBoxDocumentType()"
              class="width-100pc top-margin-8"
              >Créer un nouveau type</oui-button
            >
          </div> -->

          <oui-button
            type="text"
            iconPrefix="delete"
            class="deleteButton"
            *ngIf="files.length > 1 && showConformButton"
            (click)="onRemoveFichierFromOperation()"
            >Supprimer le document</oui-button
          >
        </ng-container>
        <ng-template #deleteLoading>
          <div class="flex-horizontal align-self-center">
            <oui-loading size="small"></oui-loading>
          </div>
        </ng-template>
      </div>

      <ng-container *ngIf="!isInstanceMode">
        <div class="ncContainer">
          <ng-container *ngIf="!!nonConformiteList && nonConformiteList.length > 0">
            <app-non-conformite-accordion
              [NCs]="nonConformiteList"
              [isShowMenu]="true"
              (onCloseNC)="closeNonConformite($event)"
              (onEditNC)="openNonConformiteFichierForm($event)"></app-non-conformite-accordion>
          </ng-container>
          <ng-container *ngIf="!!closedNCsList && closedNCsList.length > 0">
            <app-non-conformite-accordion
              [NCs]="closedNCsList"
              [isShowMenu]="true"
              [title]="'Non-conformités fermées'"
              titleClass="color-secondary subtitle-1"
              (onreOpenNC)="reOpenNonConformite($event)"></app-non-conformite-accordion>
          </ng-container></div
      ></ng-container>
      <ng-container *ngIf="isInstanceMode">
        <div class="ncContainer">
          <ng-container *ngIf="!!instancesList && instancesList.length">
            <app-non-conformite-accordion
              [NCs]="instancesList"
              [isShowMenu]="true"
              [title]="'Instances partenaire ouvertes'"
              (onCloseNC)="closeNonConformite($event)"
              (onEditNC)="openNonConformiteFichierForm($event)"></app-non-conformite-accordion>
          </ng-container>
          <ng-container *ngIf="!!closedIPsList && closedIPsList.length > 0">
            <app-non-conformite-accordion
              [NCs]="closedIPsList"
              [isShowMenu]="true"
              [title]="'Instances partenaire fermées'"
              titleClass="color-secondary subtitle-1"
              (onreOpenNC)="reOpenNonConformite($event)"></app-non-conformite-accordion>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #updateFileForm>
      <div class="flex-horizontal justify-center padding-16"><oui-loading></oui-loading></div>
    </ng-template>
  </ng-container>
  <ng-template #nonConformiteForm right-panel>
    <app-operation-file-non-conform-form
      (onNonConformiteAdd)="createNonCompliance($event)"
      (onNonConformiteEdit)="updateNonConformiteFields($event)"
      [editNC]="nonConformiteToEdit"
      (onInstanceSetIsInternal)="setIsInternalInstance($event)"
      [isInstanceMode]="isInstanceMode">
    </app-operation-file-non-conform-form>
  </ng-template>
</app-root-document-viewer>
