<div class="flex-vertical width-100pc" *ngIf="files.length > 0">
  <oui-accordion buttonPosition="title-end" [expanded]="files.length > 0" [disabled]="files.length < 1">
    <oui-accordion-header>
      <oui-accordion-actions *ngIf="withValidateAll">
        <oui-button (click)="onValidateAllClick()" [disabled]="!canBatchValidate">Tout valider</oui-button>
      </oui-accordion-actions>
      <oui-accordion-title [ngClass]="titleClass">{{ title }} ({{ files.length }})</oui-accordion-title>
    </oui-accordion-header>
    <lib-operation-files-list
      class="files-list"
      [audience]="audience"
      [checkbox]="checkbox"
      [files]="files"
      (fileClicked)="onfileClick($event)"></lib-operation-files-list>
  </oui-accordion>
</div>
