import { formatDate } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { gql, TypedDocumentNode } from 'apollo-angular';
import { OuiAutocomplete, OuiAutocompleteOption } from 'omnium-ui/form-field/public-api';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import {
  Investisseur,
  InvestisseurFilterInput,
  InvestisseursPaginatedCollectionSegment,
  InvestisseurType,
} from '../../models/generated/graphql';
import { PERSONNE_PHYSIQUE_FEMALE_GENDER_VALUE } from '../../models/graphqlData';
import { AuthService } from '../../services/auth-service.service';

const allInvestisseursWithConsultant = gql`
  query allInvestisseurs($filter: InvestisseurFilterInput, $searchText: String!) {
    investisseursByNamePaginated(skip: 0, take: 25, searchText: $searchText, where: $filter) {
      items {
        id
        code
        creationDate
        investisseurEntite {
          displayName
          personnePhysique {
            dateNaissance
            prenom
            nom
            genre
          }
        }
        investisseurConsultant(where: { actif: { eq: 1 } }) {
          consultant {
            personnePhysique {
              nom
              prenom
            }
          }
        }
      }
    }
  }
`;

const allInvestisseursBasic = gql`
  query allInvestisseurs($filter: InvestisseurFilterInput, $searchText: String!) {
    investisseursByNamePaginated(skip: 0, take: 25, searchText: $searchText, where: $filter) {
      items {
        id
        code
        investisseurEntite {
          displayName
          personnePhysique {
            dateNaissance
            prenom
            nom
            genre
          }
        }
      }
    }
  }
`;
const allInvestisseursSouscription = gql`
  query allInvestisseurs($filter: InvestisseurFilterInput, $searchText: String!) {
    investisseursByNamePaginated(skip: 0, take: 25, searchText: $searchText, where: $filter) {
      items {
        id
        code
        creationDate
        statutCode
        statutCodeNavigation {
          libelle
          actif
        }
        investisseurEntite {
          displayName
          personnePhysique {
            dateNaissance
            prenom
            nom
            genre
          }
        }
      }
    }
  }
`;

@Component({
  selector: 'app-search-investisseur-autocomplete',
  templateUrl: './search-investisseur-autocomplete.component.html',
  styleUrls: ['./search-investisseur-autocomplete.component.scss'],
})
export class SearchInvestisseurAutocompleteComponent implements AfterViewInit {
  @ViewChild('investisseurAutocomplete') investisseurAutocomplete: OuiAutocomplete<any>;

  @Input() label: string = 'Investisseur';
  @Input() initInvestisseur: Investisseur | undefined;
  @Input() isInvestisseurSet: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isContactStatutExcluded: boolean = false;
  @Input() isClientStatutOnly: boolean = false;
  @Input() typePersonnes: InvestisseurType[] = [InvestisseurType.PersonneMorale, InvestisseurType.PersonnePhysique];
  @Input() fullDisplay: boolean = true;
  @Input() defaultQueryFilter: InvestisseurFilterInput;

  @Output() onInvestisseurIdSelected = new EventEmitter<number | null | undefined>();
  @Output() onInvestisseurPersonneSelected = new EventEmitter<Investisseur | undefined>();

  investisseurs: Investisseur[] = [];

  investisseurAutocompleteOptions: OuiAutocompleteOption<number>[] = [];
  investisseurControl = new FormControl<number | undefined>(undefined);

  isInvestisseurLoading: boolean = false;
  constructor(private queryManager: QueryManagerService, private authService: AuthService) {}

  ngOnInit() {
    if (this.initInvestisseur) {
      this.investisseurs = [this.initInvestisseur!];
      this.setOptions();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    const statutContactChange = changes['isContactStatutExcluded'];
    if (statutContactChange && statutContactChange.previousValue !== undefined) {
      this.investisseurControl.setValue(undefined);
      this.onInvestisseurIdSelected.emit(null);
      this.onInvestisseurPersonneSelected.emit(undefined);
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.initInvestisseur) {
        this.investisseurControl.setValue(this.initInvestisseur.id);
      }

      if (this.isRequired) {
        this.investisseurControl.setValidators(Validators.required);
      }

      if (this.initInvestisseur && this.isInvestisseurSet) {
        this.investisseurControl.disable();
      }

      this.initSearchInvestisseurs();
    });
  }

  async initSearchInvestisseurs() {
    this.listenForInvestisseursChanges();

    if (!this.initInvestisseur) {
      this.fetchInvestisseurs();
    }
  }

  setOptions() {
    this.investisseurAutocompleteOptions = this.investisseurs.map(investisseur => ({
      label: this.formatInvestisseurLabel(investisseur),
      value: investisseur.id,
    }));
  }

  listenForInvestisseursChanges() {
    this.investisseurControl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(investisseurId => {
      if (Number.isInteger(investisseurId)) {
        const investisseurFound = this.investisseurs.find(investisseur => investisseur.id == investisseurId);
        if (investisseurFound) {
          this.onInvestisseurIdSelected.emit(investisseurFound.id);
          this.onInvestisseurPersonneSelected.emit(investisseurFound);
        }
      } else {
        if (investisseurId) {
          this.fetchInvestisseurs(investisseurId.toString());
        } else {
          this.fetchInvestisseurs();
        }
        this.onInvestisseurIdSelected.emit(null);
        this.onInvestisseurPersonneSelected.emit(undefined);
      }
    });
  }

  async fetchInvestisseurs(search: string = '') {
    this.isInvestisseurLoading = true;
    const invQuery = this.getGraphQuery();
    let investisseurResult = await firstValueFrom(
      this.queryManager.query<{ investisseursByNamePaginated: InvestisseursPaginatedCollectionSegment }>({
        query: invQuery,
        variables: {
          filter: this.buildFilter(),
          searchText: search,
        },
      })
    );
    this.investisseurs = investisseurResult.data.investisseursByNamePaginated.items ?? [];
    this.setOptions();
    this.isInvestisseurLoading = false;
  }

  buildFilter(): InvestisseurFilterInput {
    const personnePhysiqueFilter = { personnePhysique: { any: true } };
    const personneMoraleFilter = { personneMorale: { any: true } };
    //check type investisseur
    const personneFilter: InvestisseurFilterInput = { or: [] };
    if (this.typePersonnes.includes(InvestisseurType.PersonnePhysique)) {
      personneFilter.or!.push(personnePhysiqueFilter);
    }
    if (this.typePersonnes.includes(InvestisseurType.PersonneMorale)) {
      personneFilter.or!.push(personneMoraleFilter);
    }
    const investFilter: InvestisseurFilterInput = { and: [] };
    if (personneFilter.or!.length > 0) {
      investFilter.and!.push(personneFilter);
    }
    // check if contact need to be excluded
    const excludeContactStatutFilter = { statutCode: { neq: 'CONTACT' } };
    const clientStatutOnlyFilter = { statutCode: { eq: 'CLIENT' } };
    if (this.isContactStatutExcluded) {
      investFilter.and!.push(excludeContactStatutFilter);
    }
    if (this.isClientStatutOnly) {
      investFilter.and!.push(clientStatutOnlyFilter);
    }
    // add default filter
    if (this.defaultQueryFilter) {
      investFilter.and!.push(this.defaultQueryFilter);
    }
    return investFilter;
  }
  formatInvestisseurLabel(investisseur: Investisseur) {
    let label = '';

    label += investisseur?.investisseurEntite?.displayName ?? '';

    if (investisseur?.investisseurEntite?.personnePhysique) {
      if (investisseur?.investisseurEntite?.personnePhysique?.dateNaissance) {
        if (investisseur?.investisseurEntite?.personnePhysique?.genre == PERSONNE_PHYSIQUE_FEMALE_GENDER_VALUE) {
          label += ' (née le ';
        } else {
          label += ' (né le ';
        }
        label +=
          formatDate(investisseur.investisseurEntite?.personnePhysique?.dateNaissance, 'dd/MM/yyyy', 'fr_FR') + ')';
      }

      if (investisseur.creationDate && !this.authService.isBackOffice()) {
        label += ` - créé le ${formatDate(investisseur.creationDate, 'dd/MM/yyyy', 'fr_FR')}`;
      }
      if (investisseur.statutCodeNavigation?.actif) {
        label += ' - ' + investisseur.statutCodeNavigation?.libelle.toLocaleUpperCase();
      }
    }
    if (investisseur.investisseurConsultant?.length > 0) {
      label +=
        ' - ' +
        investisseur.investisseurConsultant[0].consultant.personnePhysique[0]?.nom +
        ' ' +
        investisseur.investisseurConsultant[0].consultant.personnePhysique[0]?.prenom;
      if (investisseur.investisseurConsultant?.length > 1) {
        label += ' (primaire), ';
        label +=
          investisseur.investisseurConsultant[1].consultant.personnePhysique[0]?.nom +
          ' ' +
          investisseur.investisseurConsultant[1].consultant.personnePhysique[0]?.prenom;
        label += ' (secondaire)';
      }
    }
    if (investisseur.creationDate && this.authService.isBackOffice()) {
      label += ` - créé le ${formatDate(investisseur.creationDate, 'dd/MM/yyyy', 'fr_FR')}`;
    }
    return label;
  }

  setControlValue(investisseurId: number | null | undefined) {
    if (investisseurId) {
      this.investisseurControl.setValue(investisseurId);
    } else {
      this.investisseurControl.setValue(null);
      this.investisseurControl.reset();
      this.investisseurAutocomplete.empty();
    }
    this.investisseurControl.updateValueAndValidity();
  }

  private getGraphQuery(): TypedDocumentNode<unknown, unknown> {
    if (!this.fullDisplay) {
      return allInvestisseursBasic;
    }
    if (this.authService.isBackOffice()) {
      return allInvestisseursWithConsultant;
    }
    return allInvestisseursSouscription;
  }
}
