import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DEFAULT_PAGINATION_PARAMS, PaginationParams } from '@lib/components/paginated-table/paginated-table.component';
import {
  Commande,
  CommandeFilterInput,
  CommandesFinancieresPaginatedCollectionSegment,
  Produit,
} from '@lib/models/generated/graphql';
import { AuthService } from '@lib/services/auth-service.service';
import { EnveloppeProduitService } from '@lib/services/enveloppe-produit.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { deepCopy } from '@lib/utils/deepCopy';
import { gql } from 'apollo-angular';
import { OuiTooltipPosition } from 'omnium-ui/tooltip';
import { filter, first, firstValueFrom, map } from 'rxjs';

const PAGECONTRATS = gql`
  query allCommandesFinancieresPaginated($skip: Int, $take: Int, $where: CommandeFilterInput) {
    allCommandesFinancieresPaginated(skip: $skip, take: $take, where: $where) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        nom
        produitId
        numeroContrat
        prixVenteTotalTTC
        dateActe
        produit {
          id
          nom
          nomTechniqueProduitBox
          idPartenaireNavigation {
            nom
          }
          enveloppe
          enveloppeNavigation {
            libelle
          }
        }
        investisseurCommande {
          investisseurId
          investisseur {
            investisseurEntite {
              displayName
            }
          }
        }
      }
      totalCount
    }
  }
`;

@Component({
  selector: 'app-contrat-selector',
  templateUrl: './contrat-selector.component.html',
  styleUrls: ['./contrat-selector.component.scss'],
})
export class ContratSelectorComponent {
  @Input() contratId: number;
  @Input() isModifierDisabled: boolean = true;
  @Output() onContratSelection = new EventEmitter<Commande | null>();

  // @ViewChild('contratTableSelector') contratTable: ContratsTableComponent;

  // request-filters
  enveloppeFilter: CommandeFilterInput = {};
  produitFilter: CommandeFilterInput = {};
  investisseurFilter: CommandeFilterInput = {};

  filterProduitId: number | null | undefined;
  filterEnveloppeId: number | null | undefined;
  filterInvestisseurId: number | null | undefined;

  whereFilter: CommandeFilterInput | undefined;

  // selected Contrat
  selectedContrat: Commande | null = null;
  selectedInvestisseurFullName: string;
  selectedCoInvestisseurFullName: string;

  constructor(
    private queryManager: QueryManagerService,
    private userService: AuthService,
    private enveloppeService: EnveloppeProduitService,
    private cdRef: ChangeDetectorRef
  ) {}

  // contrat-table
  paginationParams: PaginationParams = DEFAULT_PAGINATION_PARAMS;
  requestResult: { loading: boolean; data: CommandesFinancieresPaginatedCollectionSegment } | undefined;

  //toolTip
  toolTipText: string = '';
  toolTipPosition: OuiTooltipPosition = 'bottom';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contratId'].currentValue) {
      this.tryFetchCurrentContrat();
    }
  }
  ngOnInit() {
    this.tryFetchCurrentContrat();
    this.paginationParams = DEFAULT_PAGINATION_PARAMS;
    if (this.isModifierDisabled) {
      this.toolTipText = 'Pour modifier le contrat, vous devez créer un nouvel acte de gestion.';
    }
  }
  async getAllCommandes(): Promise<void> {
    const validHabilitationIds = await firstValueFrom(
      this.userService.userHabilitationCheck.pipe(
        filter(isCheck => isCheck),
        // Récupère les habilitations de l'utilisateur
        map(() => this.userService.getExtendedUserValidHabilitationIds()),
        first()
      )
    );

    this.whereFilter = this.buildCommandeFilter(validHabilitationIds);
    let requestVariables: any;
    if (this.whereFilter) {
      requestVariables = { ...this.paginationParams, where: this.whereFilter };
    } else {
      requestVariables = { ...this.paginationParams };
    }

    if (this.requestResult) {
      // clear table and set loading when fetching data
      this.requestResult = {
        loading: true,
        data: { pageInfo: { hasNextPage: false, hasPreviousPage: false }, items: [], totalCount: 0 },
      };
    }
    const result = await firstValueFrom(
      this.queryManager.query<{ allCommandesFinancieresPaginated: CommandesFinancieresPaginatedCollectionSegment }>({
        query: PAGECONTRATS,
        variables: requestVariables,
      })
    );
    if (result) {
      //FIXME: Because ATLAS data is not always as reliable as it should be:  we need to filter the result.
      //Here we check if the user has access to all of the investisseurs of the Commande to avoid further complications (Atlas API will set investisseur to  null in the result if user cannot have access)
      const resultData = deepCopy(result.data.allCommandesFinancieresPaginated);
      resultData.items = resultData.items?.filter((contrat: Commande) =>
        contrat.investisseurCommande.every(ic => ic.investisseurId && ic.investisseur)
      );
      this.requestResult = { loading: result.loading, data: resultData };
    }
  }

  // Contrat filtering
  onInvestisseurIdSelected(investisseurId: number | null | undefined) {
    if (investisseurId !== this.filterInvestisseurId) {
      this.filterInvestisseurId = investisseurId;
      this.getAllCommandes();
    }
  }

  onEnveloppeIdSelected(enveloppeId: number | null | undefined) {
    if (enveloppeId !== this.filterEnveloppeId) {
      this.filterEnveloppeId = enveloppeId;
      this.filterProduitId = undefined;
      this.getAllCommandes();
    }
  }

  onProduitSelected(produit: Produit | null | undefined) {
    if (produit?.id !== this.filterProduitId) {
      this.filterProduitId = produit?.id;
      this.getAllCommandes();
    }
  }

  buildCommandeFilter(habilitationIds: number[]): CommandeFilterInput | undefined {
    const filter: CommandeFilterInput = {};
    // from AtlasDB: 0 = "Non conforme", 1 = "En-cours" , 2 = "Close"
    // Here we filter on etatCommande to get only active and non-conforme commandes
    // We also ensure there is at least one investisseur
    filter.and = [{ etatCommande: { in: [0, 1] } }, { investisseurCommande: { any: true } }];

    filter.and.push({ produit: { habilitation: { in: [...habilitationIds] } } });
    if (this.filterEnveloppeId) {
      const enveloppeFilter: CommandeFilterInput = { produit: { enveloppe: { eq: this.filterEnveloppeId } } };
      filter.and?.push(enveloppeFilter);
    } else {
      // by default exlude commande with product with no enveloppe. enveloppe are require to select form fields
      const enveloppeFilter: CommandeFilterInput = { produit: { enveloppe: { neq: null } } };
      filter.and?.push(enveloppeFilter);
    }

    if (this.filterProduitId) {
      const produitFilter: CommandeFilterInput = { produitId: { eq: this.filterProduitId } };
      filter.and?.push(produitFilter);
    }
    if (this.filterInvestisseurId) {
      const investisseurFilter: CommandeFilterInput = {
        investisseurCommande: { some: { investisseurId: { eq: this.filterInvestisseurId } } },
      };
      filter.and?.push(investisseurFilter);
    }
    if (filter.and?.length === 0) {
      return undefined;
    }
    return filter;
  }

  onContratClick(contrat: Commande) {
    if (contrat) {
      this.selectedContrat = contrat;
      this.onContratSelection.emit(this.selectedContrat);
      if (contrat.investisseurCommande) {
        this.selectedInvestisseurFullName = `${
          contrat.investisseurCommande[0]?.investisseur?.investisseurEntite?.displayName ?? 'non renseigné'
        }`;
        if (contrat.investisseurCommande[1]?.investisseur?.investisseurEntite)
          this.selectedCoInvestisseurFullName = `${
            contrat.investisseurCommande[1]?.investisseur?.investisseurEntite?.displayName ?? 'non renseigné'
          } `;
      }
    }
  }

  onModifier() {
    this.selectedContrat = null;
    this.selectedInvestisseurFullName = '';
    this.onContratSelection.emit(this.selectedContrat);
  }

  tryFetchCurrentContrat() {
    if (this.contratId) {
      const numeroFilter: CommandeFilterInput = { id: { eq: this.contratId } };
      this.queryManager
        .query<{ allCommandesFinancieresPaginated: CommandesFinancieresPaginatedCollectionSegment }>({
          query: PAGECONTRATS,
          variables: {
            where: numeroFilter,
            skip: 0,
            take: 1,
          },
        })
        .subscribe(({ loading, data }) => {
          this.selectedContrat =
            (data.allCommandesFinancieresPaginated.items && data.allCommandesFinancieresPaginated.items[0]) ?? null;
        });
    }
  }
}
