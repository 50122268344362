import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import {
  CreateFichierOperationInput,
  FichierOperation,
  FichierOperationInGroupInput,
  NatureDocumentRequi,
  Operation,
  OperationType,
  Produit,
  TypeSignaturePartenaire,
} from '@lib/models/generated/graphql';
import { OPERATION_CATEGORIE_ID } from '@lib/models/graphqlData';
import { isMissingFichierOperation, MissingFichierOperation } from '@lib/models/types';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { OuiBannerAction } from 'omnium-ui/banner';
import { OuiDialogService } from 'omnium-ui/dialog';
import { firstValueFrom } from 'rxjs';
import { DraftOperationsService } from 'src/service/draft-operation/draft-operation.service';
import { createAndReplaceRequiredFichierOperationInOperationGroup } from 'src/service/draft-operation/mutations/create-and-replace-required-fichierOperation-in-operationGroup';
import { createBatchFichierOperationAllOperations } from 'src/service/draft-operation/mutations/create-batch-fichier-operation-multiple.mutation';
import { NoneActiveHabilitationMessageComponent } from '../../none-active-habilitation-message/none-active-habilitation-message.component';
import {
  DEFAULT_ID_FOR_MISSING_DOC,
  SelectedFileConfig,
} from '../souscription-file-items/souscription-file-items.component';

@Component({
  selector: 'app-operation-files-configuration',
  templateUrl: './operation-files-configuration.component.html',
  styleUrls: ['./operation-files-configuration.component.scss'],
})
export class OperationFilesConfigurationComponent {
  @Input() operationId?: number;
  @Input() index: number;
  // @Input() operation?: Operation;
  // @Input() investisseur?: Investisseur;
  @Output() onAddedFichierOperation = new EventEmitter<{ isMissingDoc: boolean; operationId: number }>();

  filesInformationList: (FichierOperation | MissingFichierOperation)[];
  connaissanceClientRequiredFichierOperation: (FichierOperation | MissingFichierOperation)[];
  operationFilesToDisplay: (FichierOperation | MissingFichierOperation)[];
  produit: Produit;

  operation?: Operation;

  acteGestionType = OperationType.ActeDeGestion;

  isHabilited: boolean;
  noHabilAction: OuiBannerAction = {
    label: 'En savoir plus',
    action: () => {
      this.openHabilitationMessage();
    },
  };
  constructor(
    private queryManager: QueryManagerService,
    protected draftOperationsService: DraftOperationsService,
    private dialogService: OuiDialogService
  ) {
    draftOperationsService.operationListInGroup$.subscribe(async operationList => {
      let operation = operationList.find(op => op.id === this.operationId);
      if (operation) {
        this.operation = operation;
        await this.initValues(operation);
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    const id = changes['operationId']?.currentValue;
    if (id) {
      const operationFound = this.draftOperationsService.operationGroupData?.operations.find(
        op => op.id === this.operationId
      );
      if (operationFound) {
        this.operation = operationFound;
        await this.initValues(operationFound);
      }
    }
  }

  async initValues(operation: Operation) {
    if (operation?.produit) {
      this.updateFileInformation(operation);
      this.produit = operation.produit;
      this.isHabilited = this.draftOperationsService.isUserHabilitedOperation(operation);
    }
  }
  updateFileInformation(operation?: Operation) {
    this.updateFilesInformationList(operation);
    this.updateAndCheckOperationFiles(operation);
  }

  /**
   * Set the operationFilesToDisplay based on the filesInformationList.
   * Then, check if there is a missing required document in the filesCategorieOperation list.
   * If the operationId is provided, emit an event with the result of the check.
   * @param operationId The id of the operation to check. If undefined, the event is not emitted.
   */

  private updateAndCheckOperationFiles(operation: Operation | undefined): void {
    const filesCategorieOperation = this.filesInformationList?.filter(
      fileInfo => fileInfo?.natureDocumentRequis?.natureDocument.categorieId === OPERATION_CATEGORIE_ID
    );
    const filesNotRequis = this.filesInformationList?.filter(fileInfo => !fileInfo.natureDocumentRequis);

    this.operationFilesToDisplay = [...filesCategorieOperation, ...filesNotRequis];
    if (operation) {
      // check if there is a missing required document
      const isMissingDoc = filesCategorieOperation.some(fi => isMissingFichierOperation(fi) && !fi.isFacultative);
      // emit an event with the result of the check
      this.onAddedFichierOperation.emit({ isMissingDoc, operationId: operation.id });
    }
  }

  /**
   * Update the filesInformationList:
   * 1. Initialize the list by copying the fichierOperations of the operation.
   * 2. Add the missing files of the operation config to the list.
   *    For each docRequis, check if it is already covered by an existing fichierOperation.
   *    If not, add a MissingFichierOperation to the list.
   *    If the docRequis has an autoImportCoInv, also add a MissingFichierOperation for the coInvestisseur.
   * @param operation The operation to update the filesInformationList for.
   */
  private updateFilesInformationList(operation: Operation | undefined) {
    const investisseur = this.draftOperationsService.investisseur;
    const coInvestisseur = this.draftOperationsService.coInvestisseur;

    // init the filesInformationList: we copy the fichierOperations of the operation
    let newFilesInformationList: (FichierOperation | MissingFichierOperation)[] = [];
    if (operation?.fichierOperations) {
      newFilesInformationList = [...operation.fichierOperations];
    }

    // we add the missing files
    if (operation?.operationConfig?.filteredNatureDocumentRequis && investisseur?.id) {
      operation.operationConfig.filteredNatureDocumentRequis.forEach(docRequis => {
        // check if document requis already covered by an existing fichierOperation

        this.pushMissingFileInListIfNatureNotCovered(newFilesInformationList, docRequis, operation, investisseur.id);
        if (docRequis.withAutoImportCoInv && coInvestisseur) {
          this.pushMissingFileInListIfNatureNotCovered(
            newFilesInformationList,
            docRequis,
            operation,
            coInvestisseur.id
          );
        }
      });
    }
    this.filesInformationList = newFilesInformationList;
  }
  pushMissingFileInListIfNatureNotCovered(
    newFilesInformationList: (FichierOperation | MissingFichierOperation)[],
    docRequis: NatureDocumentRequi,
    operation: Operation,
    investisseurId: number
  ) {
    const estCoInv = investisseurId === this.draftOperationsService.coInvestisseur?.id;
    const isExtranetSignature =
      operation.typeSignaturePartenaire === TypeSignaturePartenaire.ElectroniqueExtranetPartenaire;
    if (!newFilesInformationList.find(fileInfo => this.isNatureAlreadyCoveredByFile(docRequis, fileInfo, estCoInv))) {
      //file not already covered by a FichierOperation ==> create a dedicated FichierOperation Entry with -1 for id
      newFilesInformationList.push({
        id: DEFAULT_ID_FOR_MISSING_DOC,
        fileId: '',
        natureDocumentRequisId: docRequis.id,
        natureDocumentRequis: docRequis,
        operationId: operation.id,
        estHorsConfiguration: false,
        estCoInvestisseur: estCoInv,
        isFacultative: docRequis.facultatifExtranet && isExtranetSignature,
      });
    }
  }
  isNatureAlreadyCoveredByFile(
    nature: NatureDocumentRequi,
    file: FichierOperation | MissingFichierOperation,
    estCoInvestisseurFile: boolean
  ): boolean {
    return file.natureDocumentRequisId === nature.id && file.estCoInvestisseur === estCoInvestisseurFile;
  }

  async addFichierOperation(selectedFilesConfig: SelectedFileConfig[]) {
    if (Array.isArray(selectedFilesConfig) && selectedFilesConfig.length > 0) {
      if (
        selectedFilesConfig.length === 1 &&
        selectedFilesConfig[0].forceInAllOperations &&
        selectedFilesConfig[0].natureDocumentRequisId && //if there is only one selectedFileConfig and it is forced in all operations and it is required file
        this.draftOperationsService.operationGroupData?.id
      ) {
        const groupId = this.draftOperationsService.operationGroupData.id;
        const fichierOperationToCreate = this.buildFichierOperationInGroupInput(selectedFilesConfig[0], groupId);
        if (fichierOperationToCreate) {
          const updatedOperations = await this.createAndReplaceFichichierOperationInCurrentGroup(
            fichierOperationToCreate
          );
          this.draftOperationsService.updateOperationGroupDataFiles(updatedOperations);
        }
      } else if (this.operation?.id) {
        const operationId = this.operation.id;
        const fichierOperationToCreate = this.buildCreateFichierOperationInputList(selectedFilesConfig, operationId);
        if (fichierOperationToCreate && fichierOperationToCreate.length > 0) {
          await this.createAndAddFichierOperations(fichierOperationToCreate);
        }
      }
    }
  }
  buildFichierOperationInGroupInput(sfo: SelectedFileConfig, groupId: number): FichierOperationInGroupInput {
    const input: FichierOperationInGroupInput = {
      fileId: sfo.fileId,
      natureDocumentRequisId: sfo.natureDocumentRequisId,
      commentaire: sfo.comment,
      estHorsConfig: sfo.isOutOfConfig ?? false,
      estCoInvestisseur: sfo.investisseurId === this.draftOperationsService.coInvestisseur?.id,
      operationGroupId: groupId,
    };

    return input;
  }

  buildCreateFichierOperationInputList(
    selectedFilesConfig: SelectedFileConfig[],
    clickedOperationId: number
  ): CreateFichierOperationInput[] {
    const operationIds = this.draftOperationsService.operationGroupData?.operations.map(op => op.id);
    const fichierOperationToCreateInput: CreateFichierOperationInput[] = [];
    selectedFilesConfig.forEach(sfo => {
      if (sfo.forceInAllOperations) {
        operationIds.forEach(operationId => {
          fichierOperationToCreateInput.push(this.mapSelectedFileConfigToCreateFichierOperationInput(sfo, operationId));
        });
      } else {
        fichierOperationToCreateInput.push(
          this.mapSelectedFileConfigToCreateFichierOperationInput(sfo, clickedOperationId)
        );
      }
    });
    return fichierOperationToCreateInput;
  }
  mapSelectedFileConfigToCreateFichierOperationInput(
    sfo: SelectedFileConfig,
    operationId: number
  ): CreateFichierOperationInput {
    const fichierOperationToCreateInput: CreateFichierOperationInput = {
      fileId: sfo.fileId,
      operationId: operationId,
      natureDocumentRequisId: sfo.natureDocumentRequisId,
      commentaire: sfo.comment,
      estHorsConfig: sfo.isOutOfConfig ?? false,
      estCoInvestisseur: sfo.investisseurId === this.draftOperationsService.coInvestisseur?.id,
    };
    return fichierOperationToCreateInput;
  }
  async createAndReplaceFichichierOperationInCurrentGroup(input: FichierOperationInGroupInput): Promise<Operation[]> {
    const newFichierOperationResult = await firstValueFrom(
      this.queryManager.mutate<{ createAndReplaceRequiredFichierOperationInOperationGroup: FichierOperation[] }>({
        mutation: createAndReplaceRequiredFichierOperationInOperationGroup,
        variables: {
          input: input,
        },
      })
    );
    if (
      newFichierOperationResult.data?.createAndReplaceRequiredFichierOperationInOperationGroup?.every(f => f.operation)
    ) {
      return newFichierOperationResult.data?.createAndReplaceRequiredFichierOperationInOperationGroup.map(
        f => f.operation!
      );
    }
    return [];
  }

  async createAndAddFichierOperations(fichierOperationToCreate: CreateFichierOperationInput[]) {
    const newFichierOperations = await firstValueFrom(
      this.queryManager.mutate<{ createBatchFichierOperation: FichierOperation[] }>({
        mutation: createBatchFichierOperationAllOperations,
        variables: {
          batchCreateFichierOperationInput: { batchConfig: fichierOperationToCreate },
        },
      })
    );

    //if FichierOperation have been created we update current operation with then (TODO : transfer this behavior in apollo layer)
    if (newFichierOperations.data?.createBatchFichierOperation?.every(f => f.operation) && this.operation) {
      const updatedOperations = newFichierOperations.data?.createBatchFichierOperation.map(f => f.operation!);

      this.draftOperationsService.updateOperationGroupDataFiles(updatedOperations);

      this.updateFileInformation(this.operation);
    }
  }

  async onFileRemoved() {
    this.updateFileInformation(this.operation);
  }

  openHabilitationMessage() {
    this.dialogService.openDialog(
      NoneActiveHabilitationMessageComponent,
      {
        title: 'Aucune habilitation active pour ce produit',
      },
      'auto',
      '662px'
    );
  }
}
