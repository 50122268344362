import { Injectable } from '@angular/core';
import { Investisseur } from '@lib/models/generated/graphql';

import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';

const fetchInvestisseurFileData = gql`
  query investisseurById($id: Long!) {
    investisseurById(id: $id) {
      id
      code
      investisseurEntite {
        displayName
      }
      filesMetadata {
        investisseurId
        fileId
        denomination
        url
        typeDocumentCode
        etatSignature
        dateDeDerniereModification
        fileName
        typeLibelle
        actif
      }
    }
  }
`;
@Injectable({
  providedIn: 'root',
})
export class FichierOperationInvestisseurService {
  private _investisseur?: Investisseur;
  private _coInvestisseur?: Investisseur;

  constructor(private queryManager: QueryManagerService) {}

  setInvestisseur(investisseur: Investisseur) {
    this._investisseur = investisseur;
  }
  setCoInvestisseur(coInvestisseur: Investisseur) {
    this._coInvestisseur = coInvestisseur;
  }

  getInvestisseur(): Investisseur | undefined {
    return this._investisseur;
  }

  getCoInvestisseur(): Investisseur | undefined {
    return this._coInvestisseur;
  }

  getAllInvestisseurs(): Investisseur[] {
    const investisseurs: Investisseur[] = [];
    if (this._investisseur) {
      investisseurs.push(this._investisseur);
    }
    if (this._coInvestisseur) {
      investisseurs.push(this._coInvestisseur);
    }
    return investisseurs;
  }
  async updateInvestisseurFileData(investisseurId: number): Promise<Investisseur | undefined> {
    const isCoInvestisseur = investisseurId === this._coInvestisseur?.id;
    const isInvestisseur = investisseurId === this._investisseur?.id;
    if (isCoInvestisseur || isInvestisseur) {
      const result = await firstValueFrom(
        this.queryManager.query<{ investisseurById: Investisseur }>({
          query: fetchInvestisseurFileData,
          variables: {
            id: investisseurId,
          },
          fetchPolicy: 'network-only', // this ensure that the data is fetched from the server
        })
      );
      if (result.data?.investisseurById) {
        // merge fileData with existing data for investisseur
        if (isCoInvestisseur) {
          this._coInvestisseur = { ...this._coInvestisseur, ...result.data.investisseurById };
          return this._coInvestisseur;
        } else {
          this._investisseur = { ...this._investisseur, ...result.data.investisseurById };
          return this._investisseur;
        }
      }
    }
    return undefined;
  }
}
