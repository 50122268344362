import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FichierOperationsDownloadService } from 'projects/box-backoffice/src/services/fichier-operations-download.service';
import { FichierOperation } from '../../models/generated/graphql';

@Component({
  selector: 'app-file-status-accordion',
  templateUrl: './file-status-accordion.component.html',
  styleUrls: ['./file-status-accordion.component.scss'],
})
export class FileStatusAccordionComponent {
  @Input() files: FichierOperation[];
  @Input() title: string;
  @Input() titleColor: string;
  @Input() checkbox: boolean = true;
  @Input({ required: true }) audience: 'consultant' | 'gestionnaire' = 'consultant';

  @Output() fileClicked = new EventEmitter<FichierOperation>();
  @Output() validateAllClicked = new EventEmitter();

  titleClass = 'subtitle-1';
  @Input() withValidateAll: boolean = false;

  get canBatchValidate(): boolean {
    return this.files.every(file => file.fileId);
  }

  constructor(protected fileService: FichierOperationsDownloadService) {}
  ngOnInit() {
    switch (this.titleColor) {
      case 'transition':
        this.titleClass = 'color-transition subtitle-1';
        break;
      case 'valid':
        this.titleClass = 'color-valid subtitle-1';
        break;
      case 'error':
        this.titleClass = 'color-error subtitle-1';
        break;
      case 'alert':
        this.titleClass = 'color-alert subtitle-1';
        break;
      default:
        this.titleClass = 'color-alert subtitle-1';
        break;
    }
  }
  toggleFileSelection(fileId: number) {
    this.fileService.toggleFileSelection(fileId);
  }
  isIncluded(fileId: number): boolean {
    return this.fileService.isIncluded(fileId);
  }
  onfileClick(file: FichierOperation) {
    this.fileClicked.emit(file);
  }
  onValidateAllClick() {
    this.validateAllClicked.emit();
  }
}
