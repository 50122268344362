import { BoxDocumentType, FichierOperation, NatureDocumentRequi } from './generated/graphql';

export type MissingFichierOperation = {
  id: number;
  natureDocumentRequisId: number;
  natureDocumentRequis: NatureDocumentRequi;
  operationId: number;
  fileId?: string; //in case of an out of config Conseil added file, we can have a fileId (this file is in a operation, but is missing in another one)
  estHorsConfiguration: boolean;
  commentaire?: string | null; // same case as fileId
  estCoInvestisseur: boolean; // file is for co-investisseur
  isFacultative: boolean; // some required files in config can be facultative in some conditions (like extranet signature)
};
export function isMissingFichierOperation(
  fichierOperation: FichierOperation | MissingFichierOperation
): fichierOperation is MissingFichierOperation {
  return fichierOperation.id < 0;
}

export const DEFAULT_BOX_DOCUMENT_TYPE: BoxDocumentType = {
  id: 11,
  key: 'Autre',
  gedParentTypeCode: 'AUTRE',
  isParentGedTypeHaveMultipleBoxDocumentTypes: true,
  natureDocuments: [],
};
