import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationSimpleComponent } from '@lib/components/confirmation-simple/confirmation-simple.component';
import { BoxDocumentViewerService } from '@lib/components/document-viewer/document-viewer-panel/document-viewer-panel.service';
import { FilesListStatusComponent } from '@lib/components/files-list-status/files-list-status.component';
import {
  AddArianeDocumentViewerComponent,
  AddArianeResult,
} from '@lib/components/pdf-viewer/add-ariane/add-ariane-document-viewer.component';
import {
  AddUploadDocumentViewerComponent,
  AddUploadResult,
} from '@lib/components/pdf-viewer/add-upload/add-upload-document-viewer.component';
import { FichierOperationStatutEnum } from '@lib/models/FichierOperationStatutEnum';
import { LocalFileInfo } from '@lib/models/LocalFileInfo';
import {
  FichierOperation,
  FileMetadata,
  Operation,
  OperationActionRight,
  OperationStateTransitionTrigger,
  OperationType,
  OperationsPaginatedCollectionSegment,
} from '@lib/models/generated/graphql';
import { operationDetailsFragment } from '@lib/models/graphqlFragments';
import { AuthService } from '@lib/services/auth-service.service';
import { DocumentsService } from '@lib/services/documents.service';
import { BoxLayoutService } from '@lib/services/layout.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { notEmpty } from '@lib/utils/codeutils';
import { deepCopy } from '@lib/utils/deepCopy';
import { openFilePicker } from '@lib/utils/filesUtils';
import { gql } from 'apollo-angular';
import { OuiDialogService } from 'omnium-ui/dialog';
import { MenuNode } from 'omnium-ui/shared';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { filter, first, firstValueFrom, map } from 'rxjs';
import { ConsultantReponseService } from 'src/service/consultant-reponse.service';
import { SelectedFileConfig } from '../../declare-operation/souscription-file-items/souscription-file-items.component';
import { CancelDeclarationConfirmComponent } from './cancel-declaration-confirm/cancel-declaration-confirm.component';
import { NonConformiteResponseDialogComponent } from './non-conformite-response-dialog/non-conformite-response-dialog.component';

const operationDetails = gql`
  query operationDetails($where: OperationFilterInput) {
    allOperationsPaginated(skip: 0, take: 1, where: $where) {
      items {
        ...operationDetails
      }
    }
  }
  ${operationDetailsFragment}
`;

const fireConsultantFixesNCTransition = gql`
  mutation fireConsultantFixesNCTransition($operationId: Int!, $trigger: OperationStateTransitionTrigger!) {
    fireConsultantFixesNCTransition(operationId: $operationId, trigger: $trigger) {
      id
      statut {
        id
        consultantLibelle
        backOfficeLibelle
      }
      activeOperationStateTransitionTriggers
      commentaireGestionnaire
    }
  }
`;
const REMOVEFICHIEROPERATION = gql`
  mutation removeFichierOperationFromOperation($fichierOperationId: ID!) {
    removeFichierOperationFromOperation(fichierOperationId: $fichierOperationId) {
      ...operationDetails
    }
  }
  ${operationDetailsFragment}
`;
const fireStateTrigger = gql`
  mutation fireOperationStateTransitionTrigger($operationId: Int!, $trigger: OperationStateTransitionTrigger!) {
    fireOperationStateTransitionTrigger(operationId: $operationId, trigger: $trigger) {
      id
      statut {
        id
        consultantLibelle
        backOfficeLibelle
      }
      activeOperationStateTransitionTriggers
      operationActionRights
      commentaireGestionnaire
    }
  }
`;
@Component({
  selector: 'app-operation-details-consultant',
  templateUrl: './operation-details-consultant.component.html',
  styleUrls: ['./operation-details-consultant.component.scss'],
})
export class OperationDetailsConsultantComponent implements OnInit, OnDestroy {
  @ViewChild(FilesListStatusComponent)
  private filesListDisplay: FilesListStatusComponent;

  @ViewChild('documentViewerPortalContent')
  documentViewerPortalContent: TemplateRef<unknown>;

  operation: Operation;
  isSendUpdatePossible: boolean = true;
  routeId: string | null;

  isEditableMode: boolean = false;
  fileMenu: MenuNode[] = [];

  isFilesLoading: boolean = true;
  isUserHabilited: boolean;
  isDeclarationCancelable: boolean = false;

  protected viewerFile?: FileMetadata;
  protected viewerCommentaire?: string;
  selectedFile: FichierOperation;
  isOperationEndable: boolean;
  hideStatusChangeButton: boolean;

  constructor(
    private route: ActivatedRoute,
    private queryManager: QueryManagerService,
    private dialog: MatDialog,
    private router: Router,
    private layoutService: BoxLayoutService,
    private documentService: DocumentsService,
    private snackbarService: OuiSnackbarService,
    private dialogService: OuiDialogService,
    private authService: AuthService,
    protected responseService: ConsultantReponseService,
    private documentViewerPanelService: BoxDocumentViewerService,
    private viewContainerRef: ViewContainerRef
  ) {
    this.fileMenu = this.createAddFileMenuItems();
  }

  ngOnInit() {
    this.layoutService.hideHeader();
    this.routeId = this.route.snapshot.paramMap.get('operationId');
    this.getOperation();
  }

  ngOnDestroy(): void {
    this.layoutService.showHeader();
  }

  async getOperation() {
    if (this.routeId) {
      this.isFilesLoading = true;

      const result = await firstValueFrom(
        this.queryManager.query<{
          allOperationsPaginated: OperationsPaginatedCollectionSegment;
        }>({
          query: operationDetails,
          variables: {
            where: {
              id: {
                eq: Number.parseInt(this.routeId),
              },
            },
          },
        })
      );

      if (result && result.data?.allOperationsPaginated.items && result.data.allOperationsPaginated.items.length > 0) {
        const ope = result.data.allOperationsPaginated.items[0];
        await this.checkUserHabilitation(ope);
        this.setOperationData(ope);
      }

      this.isFilesLoading = false;
    }
  }

  private async checkUserHabilitation(ope: Operation) {
    const userHabiliations = await firstValueFrom(
      this.authService.userHabilitationCheck.pipe(
        filter(isCheck => isCheck),
        map(() =>
          ope.operationType === OperationType.ActeDeGestion
            ? this.authService.getExtendedUserValidHabilitationIds()
            : this.authService.getUserValidHabilitationIds()
        ),
        first()
      )
    );

    this.isUserHabilited = this.hasValidHabilitation(userHabiliations, ope.produit?.habilitation ?? 0);
  }

  hasValidHabilitation(userHabiliations: number[], produitHabilitationId: number): boolean {
    return userHabiliations.includes(produitHabilitationId);
  }
  setOperationData(operation: Operation) {
    this.operation = deepCopy(operation);
    this.responseService.setFichierOperationsAndInvestisseurs(this.operation);
    this.isEditableMode = this.isEditableFichierOperation(this.operation);
    this.isOperationEndable = this.operation.activeOperationStateTransitionTriggers.includes(
      OperationStateTransitionTrigger.PartenaireValidateOperation
    );
    this.isDeclarationCancelable = this.operation.activeOperationStateTransitionTriggers.includes(
      OperationStateTransitionTrigger.ConsultantCancelDeclaration
    );
    this.hideStatusChangeButton = this.operation.statut.id >= 400;
    if (this.isUserHabilited) {
      this.checkIsSendUpdatePossible();
    }
    if (this.filesListDisplay) {
      this.filesListDisplay.setFilesCategoryByStatus(this.operation.fichierOperations);
    }
  }

  onCancelDeclaration() {
    const modalRef = this.dialogService.openDialog(
      CancelDeclarationConfirmComponent,
      {
        operationId: this.operation.id,
      },
      'auto',
      '561px'
    );

    modalRef.afterClosed().subscribe((cancelledOps: Operation[]) => {
      if (cancelledOps && cancelledOps.map(op => op.statutId).every(s => s <= 5)) {
        this.snackbarService.open('La déclaration à bien été annulée', 'success', 5000, {
          horizontal: 'left',
          vertical: 'bottom',
        });

        const operationType = this.operation.operationType ?? cancelledOps[0].operationType;
        if (operationType == OperationType.ActeDeGestion) {
          this.router.navigate(['actes', this.operation.id ?? cancelledOps[0].id]);
        } else {
          this.router.navigate(['souscription', this.operation.id ?? cancelledOps[0].id]);
        }
      }
    });
  }

  async removeFichierFromOperation(fichierId: number) {
    this.isFilesLoading = true;
    const fromOperation = (
      await firstValueFrom(
        this.queryManager.mutate<{ removeFichierOperationFromOperation: Operation }>({
          mutation: REMOVEFICHIEROPERATION,
          variables: {
            fichierOperationId: fichierId,
          },
        })
      )
    ).data?.removeFichierOperationFromOperation;
    this.isFilesLoading = false;
    if (fromOperation) {
      this.setOperationData(fromOperation);
    }
  }

  onEditableFileClick(selectedFile: FichierOperation) {
    // because consultant can add new Files with updated status  which should not be opened in Response NC Viewer,
    // we check if there are non conformite before using Response NC Viewer, if negative we use Doc Viewer
    if (
      selectedFile.fichierOperationStatut.statut === FichierOperationStatutEnum.updatedByConsulant &&
      selectedFile.nonConformiteFichiers?.length === 0
    ) {
      this.documentViewerPanelService.openAppendedDocumentViewer(
        selectedFile,
        result => {
          if (result && result.fichierOperation?.id && typeof result.comment === 'string') {
            this.isFilesLoading = true;
            this.responseService.updateFichierOperationCommentaire(result.fichierOperation.id, result.comment ?? '');
            this.isFilesLoading = false;
          }
        },
        async removeEvent => {
          if (removeEvent.fichierOperationToRemove?.id) {
            this.isFilesLoading = true;
            await this.removeFichierFromOperation(removeEvent.fichierOperationToRemove.id);
            this.isFilesLoading = false;
          }
        }
      );
    } else {
      this.selectedFile = selectedFile;
      const portal = new TemplatePortal(this.documentViewerPortalContent, this.viewContainerRef);
      this.documentViewerPanelService.openCustomDocumentViewer(portal);
    }
  }

  closeDocumentViewer(docUpdated?: boolean) {
    this.documentViewerPanelService.closeDocumentViewer();

    if (docUpdated === true) {
      this.getOperation();
    }
  }

  onfileViewClick(selectedFile: FichierOperation) {
    this.documentViewerPanelService.openSimpleDocumentViewer({
      fileMetadata: selectedFile.metadata ?? undefined,
      commentaire: selectedFile.commentaire ?? undefined,
    });
  }

  checkIsSendUpdatePossible() {
    this.isSendUpdatePossible = this.operation.activeOperationStateTransitionTriggers.includes(
      OperationStateTransitionTrigger.ConsultantFixesNc
    );
    // if consultant can send the update, the UX is to open a reminder popup
    if (this.isSendUpdatePossible) {
      this.openNonConformiteResponseDialog();
    }
  }

  openNonConformiteResponseDialog() {
    const modalRef = this.dialogService.openDialog(NonConformiteResponseDialogComponent, undefined, 'auto', '492px');

    modalRef.afterClosed().subscribe((isValidated: boolean) => {
      if (isValidated) {
        this.fireConsultantFixesNCTransitionAndGo();
      }
    });
  }

  fireConsultantFixesNCTransitionAndGo() {
    const trigger = OperationStateTransitionTrigger.ConsultantFixesNc;
    if (this.operation.activeOperationStateTransitionTriggers.includes(trigger)) {
      this.queryManager
        .mutate<{ fireConsultantFixesNCTransition: Operation }>({
          mutation: fireConsultantFixesNCTransition,
          variables: {
            operationId: this.operation.id,
            trigger: trigger,
          },
        })
        .subscribe(result => {
          if (result.data?.fireConsultantFixesNCTransition) {
            this.snackbarService.open('Mise à jour envoyée', 'success', 5000, {
              horizontal: 'left',
              vertical: 'bottom',
            });
          }
          this.router.navigate([`operations`]).then(() => {
            window.location.reload();
          });
        });
    }
  }

  isEditableFichierOperation(operation: Operation): boolean {
    return operation.operationActionRights?.includes(OperationActionRight.FichierOperationEdition);
  }

  onEndOperation() {
    const modalRef = this.dialogService.openDialog(
      ConfirmationSimpleComponent,
      {
        title: "Terminer l'opération",
        message:
          'En cliquant sur “Valider”, votre opération sera historisée et vous ne pourrez plus revenir en arrière.',
        validateButtonLabel: 'Valider',
      },
      'auto',
      '478px'
    );

    modalRef.afterClosed().subscribe((isValidated: boolean) => {
      if (isValidated) {
        this.queryManager
          .mutate<{ fireOperationStateTransitionTrigger: Operation }>({
            mutation: fireStateTrigger,
            variables: {
              operationId: this.operation.id,
              trigger: OperationStateTransitionTrigger.PartenaireValidateOperation,
            },
          })
          .subscribe(result => {
            if (result.data?.fireOperationStateTransitionTrigger) {
              const ope = result.data?.fireOperationStateTransitionTrigger;
              this.operation.statut = ope.statut;
              this.operation.activeOperationStateTransitionTriggers = ope.activeOperationStateTransitionTriggers;
              this.operation.commentaireGestionnaire = ope.commentaireGestionnaire;
              this.operation.operationActionRights = ope.operationActionRights;
              this.setOperationData(this.operation);
            }
          });
      }
    });
  }

  createAddFileMenuItems() {
    return [
      {
        name: 'Depuis mon ordinateur',
        icon: 'upload_file',
        action: () => {
          openFilePicker(
            'target_div',
            (files: File[]) => {
              if (!files.every(file => this.documentService.isAuthorizedConsultantFile(file))) {
                this.snackbarService.open('Format ou poids de fichier non autorisé.', 'error', 5000, {
                  horizontal: 'left',
                  vertical: 'bottom',
                });
                return;
              }
              const dialogRef = this.dialog.open(AddUploadDocumentViewerComponent, {
                maxWidth: '100vw',
                maxHeight: '100vh',
                height: '100%',
                width: '100%',
                panelClass: 'full-screen-modal',
                autoFocus: false,
                data: {
                  files,
                  typeSelectable: true,
                  investisseurs: this.responseService.investisseurs,
                },
              });
              dialogRef.afterClosed().subscribe(async (result: AddUploadResult) => {
                if (Array.isArray(result?.filesInfo) && result?.filesInfo?.length > 0) {
                  const localFileInfos: LocalFileInfo[] = result.filesInfo;
                  const fileIdToFileInfo = new Map<string, LocalFileInfo>();
                  const investisseurId: number =
                    localFileInfos[0].investisseurId ?? this.responseService.investisseurs[0]?.id;
                  this.isFilesLoading = true;
                  const fileIds = await Promise.all(
                    localFileInfos.map(async fileInfo => {
                      if (investisseurId) {
                        let fileId = await this.documentService.uploadFile(
                          fileInfo.file,
                          investisseurId,
                          fileInfo.boxDocumentType,
                          fileInfo.denomination
                        );
                        if (fileId) {
                          fileIdToFileInfo.set(fileId, fileInfo);
                        }
                        return fileId;
                      }
                      return;
                    })
                  );

                  const outputData: SelectedFileConfig[] = fileIds.filter(notEmpty).map(fileId => ({
                    fileId,
                    denomination: fileIdToFileInfo.get(fileId)?.boxDocumentType?.key,
                    comment: fileIdToFileInfo.get(fileId)?.comment,
                    isOutOfConfig: true,
                    forceInAllOperations: false,
                    investisseurId:
                      fileIdToFileInfo.get(fileId)?.investisseurId ?? this.responseService.investisseurs[0]?.id,
                  }));
                  this.responseService.addFichierOperationToOperation(outputData, this.operation.id);
                  this.isFilesLoading = false;
                }
              });
            },
            true,
            true
          );
        },
      },
      {
        name: 'Depuis mes documents Ariane',
        icon: 'search',
        action: () => {
          const dialogRef = this.dialog.open(AddArianeDocumentViewerComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            panelClass: 'full-screen-modal',
            data: {
              investisseurs: this.responseService.investisseurs,
              multiple: true,
            },
          });
          dialogRef.afterClosed().subscribe((resultData: AddArianeResult) => {
            const fileInfoList = resultData?.filesInfo;
            if (fileInfoList && Array.isArray(fileInfoList) && fileInfoList.length > 0) {
              const outputData: SelectedFileConfig[] = fileInfoList.map(fi => ({
                fileId: fi.metadata.fileId!,
                comment: fi.comment,
                isOutOfConfig: true,
                forceInAllOperations: false,
                investisseurId: fi.metadata.investisseurId,
              }));
              this.responseService.addFichierOperationToOperation(outputData, this.operation.id);
            }
          });
        },
      },
    ];
  }

  onBackArrowClicked() {
    this.router.navigate(['operations']);
  }
}
