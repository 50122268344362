import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';

import { BlockBlobClient } from '@azure/storage-blob';
import { gql } from 'apollo-angular';
import { downloadZip } from 'client-zip';
import { DEFAULT_BOX_DOCUMENT_TYPE } from 'projects/box-lib/src/lib/models/types';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { deepCopy } from 'projects/box-lib/src/lib/utils/deepCopy';
import {} from 'rxjs';
import {
  BoxDocumentType,
  BoxFile,
  BoxFileConnectionInfo,
  CreateBoxDocumentTypeInput,
  CreateEmptyBoxFileInput,
  FichierOperation,
  FileMetadata,
  FileMetadataUpdateInput,
  GedTypeDocument,
  Maybe,
} from '../models/generated/graphql';
import {
  JPEG_EXTENSIONS,
  JPEG_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
  PNG_EXTENSIONS,
  PNG_MIME_TYPE,
} from '../models/graphqlData';

export type NewBoxFileInput = {
  fileName?: string;
  fileExtension?: string;
  denomination?: string;
  envoiPartenaireId?: number | null;
  operationId?: number | null;
  file: File;
};

export type ZipBlobItem = {
  name: string;
  lastModified: Date;
  input: Blob;
};

const SIZE_LIMIT = 20 * 1024 * 1024; // 20MB

const CREATE_EMPTY_GED_FILE_MUTATION = gql`
  mutation createEmptyGedFile($fileName: String!, $typeCode: String!, $denomination: String!, $investisseurId: Long!) {
    createEmptyGedFile(
      denomination: $denomination
      nomDeFichier: $fileName
      investisseurId: $investisseurId
      typeCode: $typeCode
    ) {
      fileId
      versionNumber
      url
      investisseurId
      extension
      denomination
      typeDocumentCode
      dateDeCreation
      creePar
      dateDeDerniereModification
      derniereModificationPar
      fileName
      dateSignature
      urlExpireDate
      permission
      typeLibelle
    }
  }
`;

const CREATE_EMPTY_BOX_FILE_MUTATION = gql`
  mutation createEmptyBoxFile(
    $fileName: String!
    $fileExtension: String!
    $denomination: String
    $envoiPartenaireId: Int
    $operationId: Int
  ) {
    createEmptyBoxFile(
      fileName: $fileName
      fileExtension: $fileExtension
      denomination: $denomination
      envoiPartenaireId: $envoiPartenaireId
      operationId: $operationId
    ) {
      sasUrl
      expiration
      permissions
      boxFileId
    }
  }
`;

const CREATE_EMPTY_BOX_FILE_BATCH_MUTATION = gql`
  mutation createEmptyBoxFileInBatch($inputs: [CreateEmptyBoxFileInput!]!) {
    createEmptyBoxFileInBatch(inputs: $inputs) {
      sasUrl
      expiration
      permissions
      boxFileId
    }
  }
`;

const CREATE_BOX_DOC_TYPE = gql`
  mutation createBoxDocumentType($input: CreateBoxDocumentTypeInput!) {
    createBoxDocumentType(input: $input) {
      id
      gedParentTypeCode
      key
    }
  }
`;

const CREATE_EMPTY_OPERATION_BOX_FILE_MUTATION = gql`
  mutation createEmptyBoxFileForOperation(
    $fileName: String!
    $fileExtension: String!
    $denomination: String
    $operationId: Int!
  ) {
    createEmptyBoxFileForOperation(
      fileName: $fileName
      fileExtension: $fileExtension
      denomination: $denomination
      operationId: $operationId
    ) {
      sasUrl
      expiration
      permissions
      boxFileId
    }
  }
`;

const UPDATE_BOX_FILE_METADATA_MUTATION = gql`
  mutation updateBoxFileMetadata($boxFileId: Int!, $fileName: String, $fileExtension: String, $denomination: String) {
    updateBoxFileMetadata(
      boxFileId: $boxFileId
      fileName: $fileName
      fileExtension: $fileExtension
      denomination: $denomination
    ) {
      id
      fileId
      fileExtension
      fileNameWithExtension
      denomination
    }
  }
`;
const UPDATE_GED_FILE_METADATA_MUTATION = gql`
  mutation updateGedFileMetadata($fileId: String!, $metadata: FileMetadataUpdateInput!) {
    updateGedFileMetadata(fileId: $fileId, newValue: $metadata) {
      fileId
      denomination
      typeLibelle
      typeDocumentCode
      dateDeDerniereModification
    }
  }
`;

const DELETE_BOX_FILE_MUTATION = gql`
  mutation updateBoxFileMetadata($boxFileId: Int!) {
    deleteBoxFile(boxFileId: $boxFileId)
  }
`;

const GET_FILE_METADATA_BY_ID = gql`
  query gedFileMetadataById($id: String!) {
    gedFileMetadataById(id: $id) {
      fileId
      versionNumber
      url
      extension
      actif
      urlExpireDate
      permission
    }
  }
`;

const GET_BOX_DOC_TYPES = gql`
  query gedBoxDocumentTypes($filter: BoxDocumentTypeFilterInput) {
    allBoxDocumentTypes(where: $filter) {
      id
      gedParentTypeCode
      key
      isParentGedTypeHaveMultipleBoxDocumentTypes
    }
  }
`;
const GET_GED_DOC_TYPES = gql`
  query allTypeGED {
    allTypeGED(where: { actif: { eq: true } }) {
      code
      libelle
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  environment: any;

  gedTypesCache: GedTypeDocument[] | undefined;
  boxTypesCache: BoxDocumentType[] | undefined;

  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private queryManager: QueryManagerService,
    @Inject('environment') environment: any
  ) {
    this.environment = environment;
  }

  async uploadFile(
    file: File,
    investisseurId: number,
    boxType: BoxDocumentType = DEFAULT_BOX_DOCUMENT_TYPE,
    denomination: string = DEFAULT_BOX_DOCUMENT_TYPE.key
  ): Promise<string | undefined> {
    // First create an empty file
    var result = await firstValueFrom(
      this.queryManager.mutate<{ createEmptyGedFile: FileMetadata }>({
        mutation: CREATE_EMPTY_GED_FILE_MUTATION,
        variables: {
          fileName: file.name,
          denomination: denomination,
          investisseurId: investisseurId,
          typeCode: boxType.gedParentTypeCode,
        },
      })
    );

    // then upload file content
    if (result.data?.createEmptyGedFile?.url) {
      await this.uploadBlobFileContent(file, result.data?.createEmptyGedFile?.url);
      return result.data?.createEmptyGedFile?.fileId ?? undefined;
    }
    return;
  }

  async updateBoxFileMetadata(boxFileId: number, fileName?: string, denomination?: string) {
    var result = await firstValueFrom(
      this.queryManager.mutate<{ updateBoxFileMetadata: BoxFile }>({
        mutation: UPDATE_BOX_FILE_METADATA_MUTATION,
        variables: {
          boxFileId: boxFileId,
          fileName: fileName,
          denomination: denomination,
        },
      })
    );

    return result.data?.updateBoxFileMetadata;
  }

  async updateGedFileMetadata(
    gedFileId: String,
    newMetadata: FileMetadataUpdateInput
  ): Promise<FileMetadata | undefined> {
    var result = await firstValueFrom(
      this.queryManager.mutate<{ updateGedFileMetadata: FileMetadata }>({
        mutation: UPDATE_GED_FILE_METADATA_MUTATION,
        variables: {
          fileId: gedFileId,
          metadata: newMetadata,
        },
      })
    );

    return result.data?.updateGedFileMetadata;
  }

  async deleteBoxFile(boxFileId: number): Promise<boolean> {
    var result = await firstValueFrom(
      this.queryManager.mutate<{ deleteBoxFile: Boolean }>({
        mutation: DELETE_BOX_FILE_MUTATION,
        variables: {
          boxFileId: boxFileId,
        },
      })
    );

    return result.data?.deleteBoxFile === true;
  }

  async uploadBlobFileContent(file: File, sasUrl: string): Promise<string | undefined> {
    const blockClient = new BlockBlobClient(sasUrl);

    const uploadResponse = await blockClient.uploadData(file);
    return;
  }

  async getFileSizeInBytes(sasUrl: string): Promise<number | undefined> {
    try {
      const blockClient = new BlockBlobClient(sasUrl);
      const properties = await blockClient.getProperties();
      return properties?.contentLength;
    } catch (e) {
      return undefined; // file does not exist
    }
  }

  async createNewBoxFileInBatch(inputs: NewBoxFileInput[]): Promise<number[] | undefined> {
    const batchInputs: CreateEmptyBoxFileInput[] = inputs.map(input => {
      return {
        // by default use the file name and extension of provided file but override them if provided
        fileName: input.fileName ?? input.file.name.replace(/\.[^/.]+$/, '') ?? '',
        fileExtension: input.fileExtension ?? input.file.name.split('.').pop() ?? '',
        denomination: input.denomination,
        envoiPartenaireId: input.envoiPartenaireId,
        operationId: input.operationId,
      };
    });
    let results = await firstValueFrom(
      this.queryManager.mutate<{ createEmptyBoxFileInBatch: [BoxFileConnectionInfo] }>({
        mutation: CREATE_EMPTY_BOX_FILE_BATCH_MUTATION,
        variables: {
          inputs: batchInputs,
        },
      })
    );
    const boxFileConnectionInfos = results.data?.createEmptyBoxFileInBatch;
    if (boxFileConnectionInfos && boxFileConnectionInfos.length === inputs.length) {
      const uploadInfos: { input: NewBoxFileInput; connection: BoxFileConnectionInfo }[] = inputs.map(
        (input, index) => {
          const connection = boxFileConnectionInfos[index];
          return { input, connection };
        }
      );
      const uploadPromises = uploadInfos.map(async ({ input, connection }) => {
        await this.uploadBlobFileContent(input.file, connection.sasUrl);
      });

      await Promise.all(uploadPromises);
      return uploadInfos.map(i => i.connection.boxFileId);
    }
    return undefined;
  }

  async createNewBoxFile(
    file: File,
    fileName?: string,
    fileExtension?: string,
    denomination?: string,
    envoiPartenaireId: number | null = null,
    operationId: number | null = null
  ): Promise<number | undefined> {
    // by default use the file name and extension of provided file but override them if provided
    let fileNameToSet = fileName ?? file.name.replace(/\.[^/.]+$/, '');
    let fileExtensionToSet = fileExtension ?? file.name.split('.').pop();
    let operationConfigResult = await firstValueFrom(
      this.queryManager.mutate<{ createEmptyBoxFile: BoxFileConnectionInfo }>({
        mutation: CREATE_EMPTY_BOX_FILE_MUTATION,
        variables: {
          fileName: fileNameToSet,
          fileExtension: fileExtensionToSet,
          denomination,
          envoiPartenaireId: envoiPartenaireId,
          operationId: operationId,
        },
      })
    );

    if (operationConfigResult.data?.createEmptyBoxFile) {
      await this.uploadBlobFileContent(file, operationConfigResult.data.createEmptyBoxFile.sasUrl);
    }
    return operationConfigResult.data?.createEmptyBoxFile.boxFileId;
  }

  async downloadMultipleFilesInZipFile(files: BoxFile[], fileName: string) {
    const zipItems: ZipBlobItem[] = [];
    await Promise.all(
      files.map(async f => {
        const url = f.fileConnectionInfo?.sasUrl;
        const sanitizedDocumentUrl = this.domSanitizer.sanitize(SecurityContext.URL, url ?? null);
        if (sanitizedDocumentUrl) {
          const blob = await this.getBoxBlobFromUrl(sanitizedDocumentUrl);
          zipItems.push({
            name: f.fileNameWithExtension ?? f.fileName,
            lastModified: f.lastModificationDate,
            input: blob,
          });
        }
      })
    );
    const zipBlob = await downloadZip(zipItems).blob();
    this.downloadBlob(zipBlob, fileName + '.zip');
  }

  async downloadBoxFile(file: BoxFile, fileName?: string) {
    let url = file.fileConnectionInfo?.sasUrl;
    let sanitizedDocumentUrl = this.domSanitizer.sanitize(SecurityContext.URL, url ?? null);
    if (sanitizedDocumentUrl) {
      try {
        this.downloadFileFromUrl(sanitizedDocumentUrl, fileName ?? file.fileNameWithExtension ?? file.fileName);
      } catch (error) {}
    }
  }
  async downloadBlob(typedBlob: Blob, fileName: string) {
    var cacheUrl = URL.createObjectURL(typedBlob);
    var anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = cacheUrl;
    anchor.click();
  }

  private async downloadFileFromUrl(fileUrl: string, fileName: string) {
    const typedBlob = await this.getBoxBlobFromUrl(fileUrl);
    this.downloadBlob(typedBlob, fileName);
  }

  private async getBoxBlobFromUrl(fileUrl: string) {
    const result = await firstValueFrom(this.http.get(fileUrl, { responseType: 'blob' }));
    const typedBlob = new Blob([result]);
    return typedBlob;
  }

  async downloadMultipleFichierOperations(files: FichierOperation[], fileName: string) {
    const filedatas = files.filter(f => !!f.metadata).map(f => f.metadata!);
    // launch all download in parallel
    let blobList = await this.fetchGedFiles(filedatas);
    const zipBlob = await downloadZip(blobList).blob();

    this.downloadBlob(zipBlob, fileName + '.zip');
  }
  async downloadGedFile(file: FileMetadata) {
    let url = file.url;
    if (!url && file.fileId) {
      var updatedFile = await this.getFileMetadata(file.fileId);
      url = updatedFile?.url;
    }
    let sanitizedDocumentUrl = this.domSanitizer.sanitize(SecurityContext.URL, url ?? null);
    if (sanitizedDocumentUrl) {
      try {
        await this.downloadFileFromUrl(sanitizedDocumentUrl, file.fileName ?? 'unknown' + file.extension ?? '.pdf');
      } catch (error) {}
    }
  }

  async fetchGedFiles(fileMetadata: FileMetadata[]): Promise<ZipBlobItem[]> {
    let zipItems: ZipBlobItem[] = [];

    await Promise.all(
      fileMetadata.map(async metadata => {
        let url = metadata.url;
        if (!url && metadata.fileId) {
          var updatedFile = await this.getFileMetadata(metadata.fileId);
          url = updatedFile?.url;
        }
        let sanitizedDocumentUrl = this.domSanitizer.sanitize(SecurityContext.URL, url ?? null);
        if (sanitizedDocumentUrl) {
          let blob = await this.fetchFile(
            sanitizedDocumentUrl,
            metadata.fileName ?? undefined,
            metadata.extension ?? undefined
          );
          let dateModif: Date = metadata.dateDeDerniereModification ?? new Date();
          if (blob) {
            let zipItem: ZipBlobItem = {
              name: metadata.fileName ?? 'fichier_inconnu.' + metadata.extension,
              lastModified: dateModif,
              input: blob,
            };
            zipItems.push(zipItem);
          }
        }
        return null;
      })
    );

    return zipItems;
  }

  async fetchFile(
    url?: string,
    fileName: string = 'fichier-anonyme',
    fileExtension: string = 'pdf'
  ): Promise<Blob | undefined> {
    let sanitizedDocumentUrl = this.domSanitizer.sanitize(SecurityContext.URL, url ?? null);
    if (sanitizedDocumentUrl) {
      try {
        const result = await firstValueFrom(this.http.get(sanitizedDocumentUrl, { responseType: 'blob' }));
        let typedBlob = new Blob([result]);
        return result;
      } catch (error) {}
    }
    return undefined;
  }

  public async downloadCSV(fileName: string, content: string) {
    // this BOM is required for Excel (force to open as UTF-16)
    var universalBOM = '\uFEFF';
    var blob = new Blob([universalBOM + content], { type: 'text/csv;charset=utf-16LE;' });
    // var textFile = new File([content], fileName + ".csv", { type: "text/csv;charset=utf-16;" });

    await this.downloadBlob(blob, fileName + '.csv');
  }

  async fetchAndCacheBlobDocument(
    sasUrl: Maybe<string> | undefined,
    extension: Maybe<string> | undefined
  ): Promise<string | null> {
    if (sasUrl == null) {
      return null;
    }
    let sanitizedDocumentUrl = this.domSanitizer.sanitize(SecurityContext.URL, sasUrl);

    if (sanitizedDocumentUrl) {
      try {
        const result = await firstValueFrom(this.http.get(sanitizedDocumentUrl, { responseType: 'blob' }));
        let typedBlob = new Blob([result], { type: this.getMimeType(extension) });
        return URL.createObjectURL(typedBlob);
      } catch (error) {}
    }
    return null;
  }

  async getFileMetadata(fileId: string): Promise<FileMetadata | null> {
    var result = await firstValueFrom(
      this.queryManager.query<{ gedFileMetadataById: FileMetadata }>({
        query: GET_FILE_METADATA_BY_ID,
        variables: {
          id: fileId,
        },
        fetchPolicy: 'network-only',
      })
    );
    if (result.data?.gedFileMetadataById) {
      return result.data.gedFileMetadataById;
    }
    return null;
  }

  async fetchAndCacheGedDocument(fileMetadata: FileMetadata): Promise<string | null> {
    // if fileMetadata does not contains url or extension, do fetch filemetadata for API
    var workingFileMetadata = fileMetadata;
    if (!fileMetadata.url && fileMetadata.fileId) {
      var metadata = await this.getFileMetadata(fileMetadata.fileId);
      if (metadata) {
        workingFileMetadata = metadata;
      } else {
        // fileMetadata does not contains url or extension and fetch from APi return nothing : return null
        return null;
      }
    } else if (!(fileMetadata.url && fileMetadata.fileId)) {
      // fileMetadata does not contains url or extension and no fileId available to fetch date : return null
      return null;
    }

    let sanitizedDocumentUrl = this.domSanitizer.sanitize(SecurityContext.URL, workingFileMetadata.url ?? null);

    if (sanitizedDocumentUrl) {
      try {
        const result = await firstValueFrom(this.http.get(sanitizedDocumentUrl, { responseType: 'blob' }));
        let typedBlob = new Blob([result], { type: this.getMimeType(workingFileMetadata.extension) });
        return URL.createObjectURL(typedBlob);
      } catch (error) {
        // if there is an error, try to fetch new url from API and retry
        if (workingFileMetadata.fileId) {
          var updatedFile = await this.getFileMetadata(workingFileMetadata.fileId);
          if (updatedFile) {
            return this.fetchAndCacheBlobDocument(updatedFile.url, updatedFile.extension);
          }
        }
        return null;
      }
    }
    return null;
  }

  cacheLocalFile(file: File): string {
    return URL.createObjectURL(file);
  }

  getMimeType(extension: Maybe<string> | undefined): string {
    if (extension) {
      if (PDF_EXTENSIONS.includes(extension.toLowerCase())) {
        return PDF_MIME_TYPE;
      }
      if (JPEG_EXTENSIONS.includes(extension.toLowerCase())) {
        return JPEG_MIME_TYPE;
      }
      if (PNG_EXTENSIONS.includes(extension.toLowerCase())) {
        return PNG_MIME_TYPE;
      }
    }
    return PDF_MIME_TYPE;
  }

  isAuthorizedFileSize(file: File): boolean {
    return file.size < SIZE_LIMIT;
  }
  isAuthorizedFileType(file: File): boolean {
    return file.type === PDF_MIME_TYPE || file.type === JPEG_MIME_TYPE || file.type === PNG_MIME_TYPE;
  }

  isAuthorizedConsultantFile(file: File): boolean {
    return this.isAuthorizedFileSize(file) && this.isAuthorizedFileType(file);
  }

  isViewerCompatible(extension: string): boolean {
    const READABLE = [...JPEG_EXTENSIONS, ...PDF_EXTENSIONS, ...PNG_EXTENSIONS];
    return READABLE.includes(extension.toLowerCase());
  }

  async getBoxDocumentTypeList(): Promise<BoxDocumentType[]> {
    if (!this.boxTypesCache) {
      let result = await firstValueFrom(
        this.queryManager.query<{ allBoxDocumentTypes: BoxDocumentType[] }>({
          query: GET_BOX_DOC_TYPES,
        })
      );
      if (result.data?.allBoxDocumentTypes) {
        this.boxTypesCache = deepCopy(result.data.allBoxDocumentTypes).sort((a, b) => {
          if (a.id == DEFAULT_BOX_DOCUMENT_TYPE.id) return 1;
          if (b.id == DEFAULT_BOX_DOCUMENT_TYPE.id) return -1;
          return a.key.localeCompare(b.key);
        });
      }
    }
    return this.boxTypesCache ?? [];
  }

  async getGEDDocumentTypeList(): Promise<GedTypeDocument[]> {
    if (!this.gedTypesCache) {
      let result = await firstValueFrom(
        this.queryManager.query<{ allTypeGED: GedTypeDocument[] }>({
          query: GET_GED_DOC_TYPES,
        })
      );
      if (result.data?.allTypeGED) {
        this.gedTypesCache = deepCopy(result.data.allTypeGED);
      }
    }
    return this.gedTypesCache ?? [];
  }

  async getBoxDocumentTypeById(id: number): Promise<BoxDocumentType | undefined> {
    let docList = await this.getBoxDocumentTypeList();
    return docList.find(doc => doc.id === id);
  }
  async getBoxDocumentTypeByMetadata(metadata: FileMetadata): Promise<BoxDocumentType | undefined> {
    let docList = await this.getBoxDocumentTypeList();
    // check if denomination match with an existing box document key
    if (metadata.denomination) {
      let byDenomination = await this.getBoxDocumentTypeByDenomination(metadata.denomination);
      if (byDenomination) {
        return byDenomination;
      }
    }
    // denomination does not match to any existing box document key try to identify type from GED type
    // if there only one box doc type associated to file this ged type; it means we can safely use this box type
    let byGedType = docList.find(doc => doc.gedParentTypeCode === metadata.typeDocumentCode);
    if (byGedType && !byGedType.isParentGedTypeHaveMultipleBoxDocumentTypes) {
      return byGedType;
    }
    // no box type detected ==> return default box
    return deepCopy(DEFAULT_BOX_DOCUMENT_TYPE);
  }
  async getBoxDocumentTypeByDenomination(denomination?: string): Promise<BoxDocumentType | undefined> {
    let denominationToFind = denomination;
    switch (denomination) {
      case 'Document Entrée en Relation':
        denominationToFind = "Document d'entrée en relation";
        break;
      default:
        denominationToFind = denomination;
    }

    let docList = await this.getBoxDocumentTypeList();
    // check if denomination match with an existing box document key
    return docList.find(doc => doc.key === denominationToFind);
  }

  async createBoxDocumentType(boxDocumentTypeInput: CreateBoxDocumentTypeInput): Promise<BoxDocumentType | undefined> {
    let result = await firstValueFrom(
      this.queryManager.mutate<{ createBoxDocumentType: BoxDocumentType }>({
        mutation: CREATE_BOX_DOC_TYPE,
        variables: {
          input: boxDocumentTypeInput,
        },
      })
    );
    if (result.data?.createBoxDocumentType) {
      this.boxTypesCache?.push(result.data.createBoxDocumentType);
      return result.data.createBoxDocumentType;
    }
    return undefined;
  }
}
